import { useContext, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { RecuperacionesContext } from '../Context/RecuperacionesContext';
import { useHandlers } from '../hooks/useHandlers';
import DeleteIcon from '@mui/icons-material/Delete';
import '../index.css';

import AADService from "../auth/authFunctions";

const serviceAAD = new AADService();


export const ElementoAnalizar = () => {
  const { setLocalRisk, saveReceivedDoc } = useContext(RecuperacionesContext);
  const [filePreview, setFilePreview] = useState(null);
  const [loadingFile, setLoadingFile] = useState(false);
  const [showFile, setShowFile] = useState('');
  const [fileInputKey, setFileInputKey] = useState(0);
  const { switching, changeSwitch } = useHandlers();
  const [goal, setGoal] = useState('');
  const [loadingGoal, setLoadingGoal] = useState(false);

  const router = useHistory();

  const handleFileChange = async (e) => {
    const currentFile = e.target.files[0];

    if (currentFile) {
      try {
        setLoadingFile(true);
        const reader = new FileReader();
        await new Promise((resolve) => setTimeout(resolve, 1000));
        reader.onloadend = () => {
          setFilePreview(reader.result);
        };
        reader.readAsDataURL(currentFile);
      } catch (error) {
        throw new Error('Error al leer el archivo');
      } finally {
        setLoadingFile(false);
      }
      setShowFile(currentFile.name);
    }
  };

  const handleDeleteFile = () => {
    setFilePreview(null);
    setShowFile('');
    setFileInputKey((prevKey) => prevKey + 1);
  };

  const handleSubmitFile = async (e) => {
    e.preventDefault();

    const sendBody = {
      nombre_archivo: showFile,
      archivo_codificado_base64: filePreview,
      separador: "\n\n",
      estado:
      switching[changeSwitch] === undefined ? 0 : switching[changeSwitch],
      correo: 'jonflore@bancolombia.com.co',
      objetivo: goal,
      tipo_elemento: saveReceivedDoc.tipo_elemento,
      compania: saveReceivedDoc.compania,
    };

    try {
      setLoadingGoal(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/asistentero/recibir_documento/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json ;charset-UTF-8',
            Authorization: "Bearer " + serviceAAD.getToken()
          },
          body: JSON.stringify(sendBody),
        }
      );
      const data = await response.json();
      const saveRiskDoc = data?.map((risk) => ({
        idElemento: risk.id_elemento,
        idUsuario: risk.id_usuario_modificador,
      }));
      setLocalRisk(saveRiskDoc);
    } catch (error) {
      throw new Error('Error el subir archivo');
    } finally {
      setLoadingGoal(false);
      router.push('/EncontrarRiesgos');
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h1
          className="titulo_resumen"
          style={{ fontSize: '2.25rem', lineHeight: '2.5rem', padding: '5rem' }}
        >
          Información del elemento que quieres analizar
        </h1>
      </div>

      <form onSubmit={handleSubmitFile}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <label
            className="file"
            htmlFor="dropzone-file"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '80%',
              height: '30vh',
              borderWidth: '2px',
              borderColor: '#D1D5DB',
              borderStyle: 'dashed',
              borderRadius: '0.5rem',
              cursor: 'pointer',
              backgroundColor: '#F9FAFB',
              paddingTop: '6rem',
              paddingBottom: '6rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <svg
                style={{
                  width: '4rem',
                  height: '4rem',
                  marginBottom: '1rem',
                  color: '#6B7280',
                }}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p
                style={{
                  marginBottom: '0.5rem',
                  fontSize: '0.875rem',
                  lineHeight: '1.25rem',
                  color: '#6B7280',
                }}
              >
                <span
                  style={{
                    fontWeight: 600,
                  }}
                >
                  En este espacio
                </span>{' '}
                puedes adjuntar tu documento.
              </p>
              <p>BPM, DOCX, CSV o XLSX</p>
            </div>
            <input
              key={fileInputKey}
              id="dropzone-file"
              type="file"
              style={{ display: 'none' }}
              onChange={handleFileChange}
              accept=".bpm, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
          </label>
          {loadingFile ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '0.5rem',
              }}
            >
              {showFile && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1rem',
                    width: '80%',
                    height: '10vh',
                    borderWidth: '2px',
                    borderColor: '#6ae0c9',
                    borderStyle: 'dashed',
                    borderRadius: '0.5rem',
                    padding: '1rem',
                  }}
                >
                  <p>{showFile}</p>

                  <DeleteIcon
                    onClick={handleDeleteFile}
                    type="button"
                    style={{
                      border: 'none',
                      backgroundColor: 'transparent',
                      color: 'red',
                    }}
                  />
                </div>
              )}
            </div>
          )}
          <input
            required
            style={{
              width: '80%',
              height: '10vh',
              marginTop: '2rem',
              marginBottom: '2rem',
            }}
            onChange={(e) => setGoal(e.target.value)}
            className="form-control text-left texto"
            placeholder="Si el documento es CSV o Excel en espacio describe el objetivo del elemento a analizar"
          />
          {loadingGoal ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <button type="submit" className="botonPositivo btn btn-primary">
              Encontremos los riesgos operacionales
            </button>
          )}
        </div>
      </form>
    </>
  );
};
