import { useEffect, useState } from 'react';
import { useLocalStorage } from './useLocalStorage';

import AADService from "../auth/authFunctions";

const serviceAAD = new AADService();

export const useNewIdentify = () => {
  const [localSelected] = useLocalStorage('Selected')
  const [newIdentify, setNewIdentify] = useState([]);
  const [loadingIdentify, setLoadingIdentify] = useState(false);
  const [errorIdentify, setErrorIdentify] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setLoadingIdentify(true);
        setErrorIdentify(null);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL
          }/asistentero/mostrar_view_info_proceso/jonflore@bancolombia.com.co/${localSelected}/`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json ;charset-UTF-8',
              Authorization: "Bearer " + serviceAAD.getToken()
            },
          }
        );
        const data = await response.json();
        if (data) {
          setNewIdentify(data);
        }
      } catch (error) {
        console.log(error);
        setErrorIdentify(true);
      } finally {
        setLoadingIdentify(false);
      }
    })();

    return () => {
      setNewIdentify([]);
    };
  }, []);

  const uniqueIdEvaluation = [
    ...new Set(newIdentify?.map((identify) => identify?.id_evaluacion)),
  ];

  return { uniqueIdEvaluation, newIdentify, loadingIdentify, errorIdentify };
};
