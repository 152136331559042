import React, { useEffect, useState } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Row,
  Col,
  Form,
  Alert,
  Button,
  Container,
  Modal,
} from "react-bootstrap";

import axios from "axios";

import AADService from "../../auth/authFunctions";

import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TextField from "@material-ui/core/TextField";
import TableContainer from "@material-ui/core/TableContainer";

import Select from "react-select";
import makeAnimated from "react-select/animated";

import ModalExpertos from "./Modales/ModalExpertos";

const animatedComponents = makeAnimated();

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",
    color: theme.palette.common.white,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
    heigth: "10px",
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    /* maxHeight: "60vh", */
    minHeight: "20vh",
  },

  MuiTableRow: {
    root: {
      //This can be referred from Material UI API documentation.
      heigth: "10px",
    },
  },
});

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;

    case 2:
      return <Alert variant="success">Guardó exitosamente</Alert>;

    case 3:
      return <Alert variant="danger"></Alert>;

    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;

    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;

    case 7:
      return (
        <Alert variant="warning">
          Corrige los siguientes errores:
          <br></br>• Debe completar los campos obligatorios
        </Alert>
      );

    default:
      return <p></p>;
  }
}

export default function ValoracionImpacto() {
  const classes = useStyles();
  const serviceAAD = new AADService();
  const location = useLocation();
  const history = useHistory();

  const [estadoPost, setEstadoPost] = useState({
    alerta: { id: 0, data: null },
  });

  const [listaSemestres, setListaSemestres] = useState([]);

  //---------------------------------------------------------

  const [show, setShow] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [ButtonEdit, SetButtonEdit] = React.useState(false);

  const [companias, setCompanias] = useState([]);

  const [dataExpertos, setDataExpertos] = useState([]);

  const [dataResumen, setDataResumen] = useState([]);

  const [listaResumen, setListaResumen] = useState([]);

  const [selectedExpertos, setSelectedExpertos] = useState([]);

  const [fechaCorte, setFechaCorte] = useState(null);

  const [flagFechaCorte, setFlagFechaCorte] = useState(false);

  const isSelected = (name) => selectedExpertos.indexOf(name) !== -1;

  const handleClick = (event, name) => {
    const selectedIndex = selectedExpertos.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], name);
      SetButtonEdit(true);
    } else {
      SetButtonEdit(false);
    }
    console.log(newSelected);
    setSelectedExpertos(newSelected);
  };

  const defaultValues = {
    id: null,
    companias: null,
  };

  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = methods;

  const llenarSemestres = () => {
    let fechaActual = new Date();
    let anioActual = fechaActual.getFullYear();
    let mesActual = fechaActual.getMonth();
    let semestreActual = mesActual >= 6 ? 2 : 1;
  
    let tempListaSemestres = [];
  
    for (let anio = 2022; anio <= anioActual; anio++) {
      for (let semestre = 1; semestre <= 2; semestre++) {
        if (anio === anioActual && semestre > semestreActual) {
          break;
        }
        tempListaSemestres.push({
          value: String(anio) + "-" + semestre,
          label: String(anio) + "-" + semestre,
        });
      }
    }
  
    setListaSemestres(tempListaSemestres);
    return tempListaSemestres;
  };

  const ApiResumen = (compania, lista) => {
    console.log("Compañia por la que se filtra el resumen", compania);
    console.log("lista resumen", listaResumen);

    let resumenPorCompania = [];

    if (lista) {
      resumenPorCompania = lista.filter((item) => item.compania === compania);
    } else {
      resumenPorCompania = listaResumen.filter(
        (item) => item.compania === compania
      );
    }

    console.log("Resumen filtrado por compañias", resumenPorCompania);

    setDataResumen(resumenPorCompania);
    sessionStorage.setItem("compania", compania);
  };

  const ApiFechaCorte = (fecha) => {
    let url = process.env.REACT_APP_API_URL + "/valoracion_impacto_admin/";

    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };

    try {
      axios.get(url + fecha, config).then((response) => {
        console.log(response.data.expertos, response.data.resumen);

        setDataExpertos(response.data.expertos);
        setListaResumen(response.data.resumen);

        sessionStorage.setItem("corte", fecha);
        const companiaSeleccionada = sessionStorage.getItem("compania");

        console.log(companiaSeleccionada);
        if (companiaSeleccionada !== null) {
          console.log(companiaSeleccionada);

          const listaDeResumen = response.data.resumen;
          ApiResumen(companiaSeleccionada, listaDeResumen);
          setValue("companias", {
            value: companiaSeleccionada,
            label: companiaSeleccionada,
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const EnviarValoracion = () => {
    console.log('Estoy en el envio de la valoracion')
    let ids = [];
    let ids_enviados = [];
    let ids_finalizados = [];
    console.log('dataExpertos', dataExpertos)
    dataExpertos.forEach((item) => {
      if (item.estado === "No Enviado") {
        ids.push(item.idimpacto_expertos);
      }
      if (item.estado === "Enviado") {
        ids_enviados.push(item.idimpacto_expertos);
      }
      if (item.estado === "Finalizado") {
        ids_finalizados.push(item.idimpacto_expertos);
      }
    });
    console.log('dataExpertos2', dataExpertos)
    console.log('ids', ids)
    console.log('ids_enviados', ids_enviados)
    console.log('ids_finalizados', ids_finalizados)
    let url = process.env.REACT_APP_API_URL + "/valoracion_impacto_admin/";

    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };

    try {
      axios
        .put(url + fechaCorte, { ids_expertos: ids, ids_enviados: ids_enviados, ids_finalizados: ids_finalizados }, config)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            dataExpertos.map((item) => {
              item.estado = "Enviado";
            });

            let fechaCorte = getValues("corte");
            ApiFechaCorte(fechaCorte.label);
            alert("Valoraciones enviadas o desasociadas correctamente");
          } else if (response.status >= 500) {
          } else if (response.status >= 400 && response.status < 500) {
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const VerValoracion = () => {
    if (selectedExpertos[0]) {
      let url = "http://127.0.0.1:8000/valoracion_impacto/";

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + serviceAAD.getToken(),
        },
      };
    }
  };

  const Desasociar = () => {
    console.log('Estoy en el desasociar')
    if (selectedExpertos[0]) {
      const newDataArray = dataExpertos.filter(
        (item) => item.idimpacto_expertos !== selectedExpertos[0]
      );
      console.log('Estoy en el desasociar', newDataArray)
      //ApiFechaCorte(fechaCorte);

      setDataExpertos(newDataArray);

      setSelectedExpertos([]);
    }
  };

  useEffect(() => {
    async function getCompanias() {
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + "/maestros_ro/compania/",
          {
            headers: {
              Authorization: "Bearer " + serviceAAD.getToken(),
            },
          }
        );
        let companias = response.data.map(
          ({ idcompania: value, compania: label, pais }) => ({
            value,
            label,
            pais,
          })
        );

        setCompanias(companias);
      } catch (error) {
        console.error(error);
      }
    }

    getCompanias();

    const listaDeSemestres = llenarSemestres();

    const CorteSeleccionado = sessionStorage.getItem("corte");

    if (CorteSeleccionado === null) {
      let objCorte = listaDeSemestres[listaDeSemestres.length - 1];

      setFechaCorte(objCorte.label);
      setValue("corte", objCorte);
      ApiFechaCorte(objCorte.label);
      setFlagFechaCorte(true);
    } else {
      setFechaCorte(CorteSeleccionado);
      setValue("corte", {
        value: CorteSeleccionado,
        label: CorteSeleccionado,
      });
      ApiFechaCorte(CorteSeleccionado);
      setFlagFechaCorte(true);

      const companiaSeleccionada = sessionStorage.getItem("compania");

      console.log(companiaSeleccionada);
      if (companiaSeleccionada !== null) {
        ApiResumen(companiaSeleccionada);
      }
    }
  }, []);

  return (
    <>
      <AlertDismissibleExample alerta={estadoPost} />
      <Container fluid>
        <FormProvider {...methods}>
          {/* <----------------------------------------Modales----------------------------------------> */}
          <ModalExpertos
            show={show}
            onHide={() => {
              setShow(false);
              setIsEditing(false);
              setSelectedExpertos([]);
            }}
            isEditing={isEditing}
            data={dataExpertos}
            setData={setDataExpertos}
            selected={selectedExpertos}
            fechaCorte={fechaCorte}
            ApiExpertos={ApiFechaCorte}
          />
          {/* <----------------------------------------Titulo----------------------------------------> */}
          <Row className="mb-3 mt-3">
            <Col sm={12} xs={12}>
              <h1 className="titulo">Valoración Impacto ADMINISTADOR</h1>
            </Col>
          </Row>
          <hr />
          {/* <----------------------------------------Formulario----------------------------------------> */}

          <Row className="mb-4">
            <Col sm={2} xs={12}>
              <label className="forn-label label">Fecha Corte</label>
            </Col>
            <Col sm={4} xs={12}>
              <Controller
                control={control}
                name="corte"
                rules={{
                  required: "Te faltó completar este campo",
                }}
                render={({ field }) => (
                  <Select
                    components={animatedComponents}
                    options={listaSemestres}
                    onChange={(e) => {
                      field.onChange(e);
                      setFechaCorte(e.label);
                      ApiFechaCorte(e.label);
                      setFlagFechaCorte(true);
                    }}
                    value={field.value}
                    placeholder="Seleccione la Fecha de Corte"
                  />
                )}
              />
              <p className="text-center">{errors.corte?.message}</p>
            </Col>
          </Row>

          {!!flagFechaCorte ? (
            <>
              <Row className="mb-4">
                <Col sm={12} xs={12}>
                  <label>
                    *Seleccione los expertos y las entidades para los cuales
                    desea que califique las categorías
                  </label>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col sm={6} xs={12}></Col>

                <Col sm={2} xs={12}>
                  {selectedExpertos[0] ? (
                    <button
                      type="button"
                      className="btn botonPositivo"
                      onClick={VerValoracion}
                    >
                      Ver
                    </button>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col sm={2} xs={12}>
                  <button
                    type="button"
                    className="btn botonPositivo"
                    onClick={() => setShow(true)}
                  >
                    Asociar
                  </button>
                </Col>

                <Col sm={2} xs={12}>
                  {selectedExpertos[0] ? (
                    <button
                      type="button"
                      className="btn botonNegativo"
                      onClick={Desasociar}
                    >
                      Desasociar
                    </button>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <Row className="mb-4">
                <Paper className={classes.root}>
                  <TableContainer
                    component={Paper}
                    className={classes.container}
                  >
                    <Table>
                      {/* Inicio de encabezado */}
                      <TableHead className="titulo">
                        <TableRow>
                          <StyledTableCell padding="checkbox"></StyledTableCell>
                          <StyledTableCell align="left">
                            ID Valoración
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Experto
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Compañias
                          </StyledTableCell>
                          <StyledTableCell align="left">Estado</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      {/* Fin de encabezado */}
                      {/* Inicio de cuerpo de la tabla */}
                      <TableBody>
                        {dataExpertos.map((row, index) => {
                          const isItemSelected = isSelected(
                            row.idimpacto_expertos
                          );
                          return (
                            <StyledTableRow
                              key={row.idimpacto_expertos}
                              hover
                              onClick={(event) =>
                                handleClick(event, row.idimpacto_expertos)
                              }
                              selected={isItemSelected}
                              role="checkbox"
                              tabIndex={-1}
                            >
                              <StyledTableCell component="th" scope="row">
                                <Checkbox checked={isItemSelected} />
                              </StyledTableCell>

                              <StyledTableCell align="left">
                                {row.idimpacto_expertos
                                  ? row.idimpacto_expertos
                                  : null}
                              </StyledTableCell>

                              <StyledTableCell align="left">
                                {row.nombre_usuario ? row.nombre_usuario : null}
                              </StyledTableCell>

                              <StyledTableCell align="left">
                                {row.compania ? row.compania : null}
                              </StyledTableCell>

                              <StyledTableCell align="left">
                                {row.estado ? row.estado : null}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                      {/* Fin de cuerpo de la tabla */}
                    </Table>
                  </TableContainer>
                </Paper>
              </Row>
              <Row className="mb-4">
                <Col sm={10} xs={12}>
                  {" "}
                </Col>
                <Col sm={2} xs={12}>
                  <button
                    type="button"
                    className="btn botonPositivo"
                    onClick={EnviarValoracion}
                  >
                    Enviar y Guardar
                  </button>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}

          <Row className="mb-4">
            <Col sm={12} xs={12}>
              <label>*Ver resultados por categoría</label>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={2} xs={12}>
              <label className="forn-label label">Compañia</label>
            </Col>
            <Col sm={4} xs={12}>
              <Controller
                //is a prop that we get back from the useForm Hook and pass into the input.
                control={control}
                //is how React Hook Form tracks the value of an input internally.
                name={"companias"}
                //render is the most important prop; we pass a render function here.
                render={({
                  //The function has three keys: field , fieldState, and formState.
                  field: { onChange, value }, // The field object exports two things (among others): value and onChange
                }) => (
                  <Select
                    components={animatedComponents}
                    options={companias}
                    onChange={(e) => {
                      onChange(e);
                      ApiResumen(e.label);
                    }}
                    value={value}
                    placeholder="Seleccione la compañia"
                  />
                )}
                rules={{
                  required: false,
                }}
              />
              <p className="text-center">{errors.companias?.message}</p>
            </Col>
          </Row>

          <Row className="mb-4">
            <Paper className={classes.root}>
              <TableContainer component={Paper} className={classes.container}>
                <Table>
                  <TableHead className="titulo">
                    <TableRow>
                      <StyledTableCell padding="checkbox"></StyledTableCell>
                      <StyledTableCell align="left">Categoría</StyledTableCell>
                      <StyledTableCell align="left">
                        Subcategoría
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Reputacional
                      </StyledTableCell>
                      <StyledTableCell align="left">Legal</StyledTableCell>
                      <StyledTableCell align="left">
                        Operacional
                      </StyledTableCell>
                      <StyledTableCell align="left">Contagio</StyledTableCell>
                      <StyledTableCell align="left">Total</StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {dataResumen.map((row, index) => {
                      return (
                        <StyledTableRow
                          key={row.idcausa}
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StyledTableCell
                            component="th"
                            scope="row"
                          ></StyledTableCell>

                          <StyledTableCell component="th" scope="row">
                            {row.nombre_categoria ? row.nombre_categoria : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.nombre_subcategoria
                              ? row.nombre_subcategoria
                              : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.riesgo_reputacional
                              ? row.riesgo_reputacional
                              : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.riesgo_legal ? row.riesgo_legal : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.riesgo_operacional
                              ? row.riesgo_operacional
                              : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.riesgo_contagio ? row.riesgo_contagio : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.total ? row.total : null}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Row>
        </FormProvider>
      </Container>
    </>
  );
}
