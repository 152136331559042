import axios from "axios";
import AADService from "../../../auth/authFunctions";
import React, { useState, useEffect } from "react";
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import { Row, Col, Button, Container, Modal } from "react-bootstrap";

import Select from "react-select";
import makeAnimated from "react-select/animated";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const animatedComponents = makeAnimated();

export default function ModalSeguimientoLAFT(props) {
  const serviceAAD = new AADService();
  const [listaSegmentos, setListaSegmentos] = useState([]);

  const defaultValues = {
    id: null,
    estado: null,
    segmento: null,
    variable1: null,
    variable2: null,
    valor: null,
    resultado: null,
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();

  const onSubmit = async (data) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };

    let dataEnviar = {};

    console.log("Datos a enviar al back", data);

    //let response;

    // try {
    //   if (!!props.isEditing) {
    //     dataEnviar.idtipologias = data.id;
    //     dataEnviar.estado = data.estado.value;
    //     response = await axios.put(
    //       process.env.REACT_APP_API_URL + "/tipologias/" + data.id,
    //       dataEnviar,
    //       config
    //     );
    //   } else {
    //     response = await axios.post(
    //       process.env.REACT_APP_API_URL + "/tipologias",
    //       dataEnviar,
    //       config
    //     );
    //   }
    // } catch (error) {
    //   console.error(error);
    // }
    // if (response.status >= 200 && response.status < 300) {
    //   alert("Guardado con éxito");
    //   props.dataTipologias.push(response.data);
    //   reset();
    //   props.onHide();
    // } else if (response.status >= 300 && response.status < 400) {
    //   setEstadoPost(4);
    // } else if (response.status >= 400 && response.status < 512) {
    //   setEstadoPost(5);
    // }
  };

  const onError = (errors) => {
    console.log(errors);
  };

  useEffect(() => {
    //---------------------- Listas  ---------------------
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };

    let APIS = [fetch(process.env.REACT_APP_API_URL + "/segmentos", config)];

    Promise.all(APIS)
      .then(async ([segmentos]) => {
        const list = await segmentos.json();

        let listaFiltrada = list.filter((item) => item.cluster !== null);

        let listSegmentos = listaFiltrada.map(
          ({ idsegmentos: value, cluster: label }) => ({
            value,
            label,
          })
        );

        setListaSegmentos(listSegmentos);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.show]);

  useEffect(() => {
    if (Array.isArray(props.dataTipologias)) {
      if (props.selected[0] && !!props.isEditing) {
        props.dataTipologias.forEach((obj) => {
          if (obj.idtipologias === props.selected[0]) {
          }
        });
      } else {
        reset();
      }
    }
  }, [props.isEditing]);

  return (
    // <FormProvider {...methods}>
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Seguimiento LAFT
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          {!!props.isEditing ? (
            <Row className="mb-4">
              <Col sm={2} xs={12}>
                <label className="forn-label label">ID</label>
              </Col>
              <Col sm={4} xs={12}>
                <input
                  {...register("id")}
                  disabled
                  type="text"
                  className="form-control text-center texto"
                  placeholder="ID"
                  //value={id}
                />
              </Col>
            </Row>
          ) : (
            <></>
          )}

          <Row className="mb-4">
            <Col sm={2} xs={12}>
              <label className="forn-label label">Periodo</label>
            </Col>
            <Col sm={4} xs={12}>
              <Controller
                control={control}
                name={"periodo"}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    fullsize
                    disabled={true}
                    selected={Date.now()}
                    onChange={onChange}
                    className="form-control"
                  />
                )}
                rules={{
                  required: "Te faltó completar este campo",
                }}
              />
              <p className="text-center">{errors.nombre?.message}</p>
            </Col>

            <Col sm={2} xs={12}>
              <label className="forn-label label">Segmento</label>
            </Col>

            <Col sm={4} xs={12}>
              <Controller
                control={control}
                name={"segmentos"}
                render={({ field: { onChange, value } }) => (
                  <Select
                    components={animatedComponents}
                    options={listaSegmentos}
                    onChange={onChange}
                    value={value}
                    placeholder={"Seleccione el Segmento"}
                  />
                )}
                // rules={{
                //   required: "¡Chispas!, Te faltó seleccionar este campo",
                // }}
              />
              <p className="text-center">{errors.segmentos?.message}</p>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={2} xs={12}>
              <label className="forn-label label">Variable 1</label>
            </Col>
            <Col sm={4} xs={12}>
              <input
                {...register("nombre")}
                type="text"
                disabled={false}
                className="form-control text-center texto"
                //placeholder="Nombre de la Variable"
              />
              <p className="text-center">{errors.nombre?.message}</p>
            </Col>

            <Col sm={2} xs={12}>
              <label className="forn-label label">Variable 2</label>
            </Col>
            <Col sm={4} xs={12}>
              <input
                {...register("descripcion")}
                type="text"
                disabled={false}
                className="form-control text-center texto"
                //placeholder="Descripción"
              />
              <p className="text-center">{errors.descripcion?.message}</p>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={2} xs={12}>
              <label className="forn-label label">Valor Indicador*</label>
            </Col>
            <Col sm={4} xs={12}>
              <input
                {...register("Valor", {
                  required: false,
                })}
                disabled={false}
                type="text"
                className="form-control text-center texto"
                //placeholder="Valor"
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="botonPositivo"
          onClick={handleSubmit(onSubmit, onError)}
        >
          Guardar
        </Button>
        <Button className="botonNegativo" onClick={props.onHide}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
    //</FormProvider>
  );
}
