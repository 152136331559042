import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import AADService from "../../auth/authFunctions";

import Select from "react-select";
import makeAnimated from "react-select/animated";

import { Controller } from "react-hook-form";

const animatedComponents = makeAnimated();

export const FormInputPeriodicidad = ({ name, control, label }) => {
  const serviceAAD = new AADService();

  const [options, setOptions] = useState([]);

  useEffect(() => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };

    let APIS = [
      fetch(process.env.REACT_APP_API_URL + "/generales/controles/Periodicidad", config),
    ];

    Promise.all(APIS)
      .then(async ([listas]) => {
        const list = await listas.json();

        let elementosListas = await list.map(
          ({
            valor: label,
            idm_parametrosgenerales: value,
            grupo,
            parametro,
          }) => ({
            label,
            value,
            grupo,
            parametro,
          })
        );

        setOptions(elementosListas);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Controller
      //is a prop that we get back from the useForm Hook and pass into the input.
      control={control}
      //is how React Hook Form tracks the value of an input internally.
      name={name}
      //render is the most important prop; we pass a render function here.
      render={({
        //The function has three keys: field , fieldState, and formState.
        field: { onChange, value }, // The field object exports two things (among others): value and onChange
      }) => (
        <Select
          components={animatedComponents}
          options={options}
          onChange={onChange}
          value={value}
          placeholder={label}
        />
      )}
      rules={{
        required: "Te faltó completar este campo",
      }}
      defaultValue=""
    />
  );
};
