import React, { useEffect, useState, useContext, useCallback } from "react";
import { Link, Routes, Route, useHistory, useLocation } from "react-router-dom";
import { Button, Row, Col, Form, Container } from "react-bootstrap";
import { useForm, Controller, FormProvider } from "react-hook-form";
import AADService from "../../auth/authFunctions";

import { withStyles, makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";
import Toolbar from "@material-ui/core/Toolbar";

import Loader from "react-loader-spinner";

import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",
    color: theme.palette.common.white,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
}))(TableRow);
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "60vh",
    minHeight: "60vh",
  },
});

export default function Segmentos() {
  const serviceAAD = new AADService();
  const classes = useStyles();
  let history = useHistory();

  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [buscando, setBuscando] = React.useState(null);

  const [ButtonEdit, SetButtonEdit] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(false);
  const [dataBusqueda, setDataBusqueda] = React.useState([]);

  const [listaSegementosApriori, setListaSegementosApriori] = React.useState(
    []
  );
  const [listaSegementos, setListaSegementos] = React.useState([]);
  const [dataCompania, setDataCompania] = React.useState([]);
  const [dataFactor, setDataFactor] = React.useState([]);

  const [listaSemestres, setListaSemestres] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], name);
      SetButtonEdit(true);
    } else {
      SetButtonEdit(false);
    }
    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const Editar = () => {
    history.push({
      pathname: "/EditarSegmento",
      state: { idSegmento: selected[0] },
    });
  };

  async function buscar(e) {
    e.persist();

    //await setBuscando(e.target.value);
    var search = data.filter((item) => {
      if (
        String(item.idsegmentos)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.compania)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.factor)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.corte_informacion)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.segmento_a_priori)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.cluster)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.descripcion)
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }
    });
    //await setBuscando(e.target.value);
    //await setDataBusqueda(search);
    await setDatosBusqueda(e.target.value, search);
  }

  const setDatosBusqueda = async (buscando, busqueda) => {
    setBuscando(buscando);
    setDataBusqueda(busqueda);
  };

  const defaultValues = {
    compania: null,
    factor: null,
    segmento: null,
    corte: null,
  };

  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = methods;

  const llenarSemestres = () => {
    let fechaActual = new Date();
    let anioActual = fechaActual.getFullYear();
    let mesActual = fechaActual.getMonth();
    let semestreActual = mesActual >= 6 ? 2 : 1;
  
    let tempListaSemestres = [];
  
    for (let anio = 2022; anio <= anioActual; anio++) {
      for (let semestre = 1; semestre <= 2; semestre++) {
        if (anio === anioActual && semestre > semestreActual) {
          break;
        }
        tempListaSemestres.push({
          value: String(anio) + "-" + semestre,
          label: String(anio) + "-" + semestre,
        });
      }
    }
  
    setListaSemestres(tempListaSemestres);
    return tempListaSemestres;
  };

  const Filtrar = () => {
    setData(listaSegementos);
    const compania = getValues("compania");
    const factor = getValues("factor");
    const segmento = getValues("segmento");
    const corte = getValues("corte");

    console.log(compania);

    var search = listaSegementos.filter((item) => {
      if (
        (compania === undefined ||
          compania === null ||
          compania.label === "Todas" ||
          compania.label == item.compania) &&
        (factor === undefined ||
          factor === null ||
          factor.label === "Todas" ||
          factor.label == item.factor) &&
        (segmento === undefined ||
          segmento === null ||
          segmento.label === "Todas" ||
          segmento.label == item.segmento_a_priori) &&
        (corte === undefined ||
          corte === null ||
          corte.label === "Todas" ||
          corte.label == item.corte_informacion)
      ) {
        return item;
      }
    });

    setData(search);
    setDataBusqueda(search);
  };

  useEffect(() => {
    //---------------------- Listas  ---------------------
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };

    let APIS = [
      fetch(process.env.REACT_APP_API_URL + "/segmentos/", config),
      fetch(process.env.REACT_APP_API_URL + "/maestros_ro/compania/", config),
      fetch(process.env.REACT_APP_API_URL + "/generales/Causas/Factor", config),
    ];

    Promise.all(APIS)
      .then(async ([segmentos, companias, factores]) => {
        const listSegmentos = await segmentos.json();

        setListaSegementos(listSegmentos);
        setData(listSegmentos);
        setDataBusqueda(listSegmentos);

        let listSegmentosN1 = listSegmentos.map(
          ({ idsegmentos: value, segmento_a_priori: label, nivel }) => ({
            value,
            label,
            nivel,
          })
        );

        let ListaSegmentosN1 = listSegmentosN1.filter(
          (segmento) => segmento.nivel === "Segmento a priori"
        );

        ListaSegmentosN1.unshift({
          value: 0,
          label: "Todas",
        });

        setListaSegementosApriori(ListaSegmentosN1);

        const listCompanias = await companias.json();

        let listaCompanias = listCompanias.map(
          ({ idcompania: value, compania: label }) => ({
            value,
            label,
          })
        );

        listaCompanias.unshift({ value: 0, label: "Todas" });

        setDataCompania(listaCompanias);

        const listFactores = await factores.json();

        let listaFactores = listFactores.map(
          ({ idm_parametrosgenerales: value, valor: label }) => ({
            value,
            label,
          })
        );

        listaFactores.unshift({
          value: 0,
          label: "Todas",
        });
        setDataFactor(listaFactores);
      })
      .catch((error) => {
        console.log(error);
      });

    //LLamado a la funcion llenarSemestres() para inicializar la lista que despliega las fechas de corte
    llenarSemestres();
    setLoadingData(false);
  }, []);

  return (
    <>
      <Container fluid>
        <Row className="mb-3 mt-3">
          <Col sm={8} xs={12}>
            <h1 className="titulo">Segmentos</h1>
          </Col>
        </Row>
        <FormProvider {...methods}>
          <Row className="mb-3 mt-3">
            <Col sm={4} xs={12}>
              <Form>
                <Form.Control
                  value={buscando}
                  onChange={(e) => buscar(e)}
                  type="text"
                  placeholder="Buscar"
                />
              </Form>
            </Col>

            <Col sm={4} xs={12}></Col>

            <Col sm={2} xs={12}>
              {ButtonEdit ? (
                <Button className="botonNegativo" onClick={Editar}>
                  Editar
                </Button>
              ) : (
                <></>
              )}
            </Col>

            <Col sm={2} xs={12}>
              <Link to="/CrearSegmento">
                <Button className="botonPositivo">Crear</Button>
              </Link>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col sm={2} xs={12}>
              <Controller
                control={control}
                name={"compania"}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Select
                    components={animatedComponents}
                    options={dataCompania}
                    onChange={(e) => {
                      onChange(e);
                      Filtrar();
                    }}
                    value={value}
                    placeholder="Compañia"
                  />
                )}
              />
            </Col>
            <Col sm={2} xs={12}>
              <Controller
                control={control}
                name={"factor"}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Select
                    components={animatedComponents}
                    options={dataFactor}
                    onChange={(e) => {
                      onChange(e);
                      Filtrar();
                    }}
                    value={value}
                    placeholder="Factor"
                  />
                )}
              />
            </Col>

            <Col sm={2} xs={12}>
              <Controller
                control={control}
                name={"segmento"}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Select
                    components={animatedComponents}
                    options={listaSegementosApriori}
                    onChange={(e) => {
                      onChange(e);
                      Filtrar();
                    }}
                    value={value}
                    placeholder="Segmento a priori"
                  />
                )}
              />
            </Col>

            <Col sm={2} xs={12}>
              <Controller
                control={control}
                name="corte"
                render={({ field }) => (
                  <Select
                    components={animatedComponents}
                    options={listaSemestres}
                    onChange={(e) => {
                      field.onChange(e);
                      Filtrar();
                    }}
                    value={field.value}
                    placeholder="Fecha de Corte"
                  />
                )}
              />
            </Col>
          </Row>

          {loadingData ? (
            <Row className="mb-3 mt-5">
              <Col>
                <Loader
                  type="Oval"
                  color="#FFBF00"
                  style={{ textAlign: "center", position: "static" }}
                />
              </Col>
            </Row>
          ) : (
            <Paper className={classes.root}>
              <TableContainer component={Paper} className={classes.container}>
                <Table className={"text"} aria-label="sticky table">
                  <TableHead className="titulo">
                    <TableRow>
                      <StyledTableCell padding="checkbox"></StyledTableCell>

                      <StyledTableCell>ID Segmento</StyledTableCell>

                      <StyledTableCell align="left">Compañia</StyledTableCell>

                      <StyledTableCell align="left">Factor</StyledTableCell>

                      <StyledTableCell align="left">
                        Segmento a priori
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        Fecha de corte
                      </StyledTableCell>

                      <StyledTableCell align="left">Cluster</StyledTableCell>

                      <StyledTableCell align="left">
                        Descripción cluster
                      </StyledTableCell>

                      <StyledTableCell align="left">Estado</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {/* Fin de encabezado */}
                  {/* Inicio de cuerpo de la tabla */}
                  <TableBody>
                    {dataBusqueda
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.idsegmentos);
                        return (
                          <StyledTableRow
                            key={row.idsegmentos}
                            hover
                            onClick={(event) =>
                              handleClick(event, row.idsegmentos)
                            }
                            selected={isItemSelected}
                            role="checkbox"
                            tabIndex={-1}
                          >
                            <StyledTableCell component="th" scope="row">
                              <Checkbox checked={isItemSelected} />
                            </StyledTableCell>

                            <StyledTableCell component="th" scope="row">
                              {row.idsegmentos ? row.idsegmentos : null}
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {row.compania !== null ? row.compania : null}
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {row.factor ? row.factor : null}
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {row.segmento_a_priori
                                ? row.segmento_a_priori
                                : null}
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {row.corte_informacion
                                ? row.corte_informacion
                                : null}
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {row.cluster ? row.cluster : null}
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {row.descripcion ? row.descripcion : null}
                            </StyledTableCell>

                            <StyledTableCell align="left">
                              {row.estado ? "Activo" : "Inactivo"}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                  {/* Fin de cuerpo de la tabla */}
                </Table>
              </TableContainer>
              {/* Inicio de paginación */}
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              {/* Fin de paginación */}
            </Paper>
          )}
        </FormProvider>
      </Container>
    </>
  );
}
