import { CircularProgress } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useHandlers } from '../hooks/useHandlers';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { RecuperacionesContext } from '../Context/RecuperacionesContext';

import AADService from "../auth/authFunctions";

const serviceAAD = new AADService();

export const AñadirRiesgo = () => {
  const [localSelected, _] = useLocalStorage('Selected');
  const { localRisk } = useContext(RecuperacionesContext);
  const { switching, changeSwitch } = useHandlers();
  const [missingRisks, setMissingRisks] = useState([]);
  const [waiting, setWaiting] = useState(false);
  const [value, setValues] = useState();
  const IdUser = localRisk?.map((info) => info?.idUsuario);

  const createNewRisk = async () => {
    try {
      setWaiting(true);

      const requestBody = {
        id_elemento: Number(localSelected) || Number(localRisk[0].idElemento),
        id_usuario: value?.id_usuario || IdUser,
        id_categoria:
          value?.id_categoria === undefined ? 2 : value?.id_categoria,
        activo:
          switching[changeSwitch] === undefined ? 0 : switching[changeSwitch],
      };

      await fetch(
        `${process.env.REACT_APP_API_URL}/asistentero/incluir_riesgo_nuevo/`,
        {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Content-Type': 'application/json; charset-UTF-8',
            Authorization: "Bearer " + serviceAAD.getToken()
          },
        }
      );
    } catch (error) {
      throw new Error(`An error ocurred in create new risk ${error}`);
    } finally {
      setWaiting(false);
      window.history.back();
    }
  };

  useEffect(() => {
    //Listar elementos faltantes
    (async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL
          }/asistentero/enviar_categorias_riesgo_faltantes/jonflore@bancolombia.com.co/${localSelected || Number(localRisk[0].idElemento)
          }/`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json ;charset-UTF-8',
              Authorization: "Bearer " + serviceAAD.getToken()
            },
          }
        );
        if (response.ok) {
          const list = await response.json();
          setMissingRisks(list);
        }
      } catch (error) {
        throw new Error(`An error has ocurred an listed ${error}`);
      }
    })();
  }, []);

  const handleSelectChange = (event) => {
    const setValue = event.target.value;
    setValues(missingRisks.find((risk) => risk.nombre_categoria === setValue));
  };

  return (
    <>
      <section style={{ textAlign: 'center' }}>
        <h1
          className="titulo_resumen"
          style={{
            fontSize: '2.25rem',
            lineHeight: '2.5rem',
            textAlign: 'center',
          }}
        >
          Crear nuevo riesgo para el elemento
          <br />
        </h1>
      </section>
      :
      {waiting ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '5rem',
          }}
        >
          <CircularProgress size={30} />
        </div>
      ) : (
        <form onSubmit={createNewRisk}>
          <section
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '5rem',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                paddingRight: '1rem',
              }}
            >
              <select
                required
                style={{
                  marginBottom: '5rem',
                  borderRadius: '0.5rem',
                  padding: '0.7rem',
                  borderStyle: 'solid',
                }}
                onChange={handleSelectChange}
              >
                <option disabled selected>
                  Selecciona un riesgo
                </option>
                {missingRisks?.map((item) => (
                  <option key={item.id} value={item.nombre_categoria}>
                    {item.nombre_categoria}
                  </option>
                ))}
              </select>
            </div>
            <button
              textAlignype="submit"
              className="botonPositivo btn btn-primary"
            >
              Añadir riesgo
            </button>
          </section>
        </form>
      )}
    </>
  );
};
