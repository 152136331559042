import React, { useEffect, useState, useContext, useCallback } from "react";
import { useForm, Controller, useWatch, FormProvider } from "react-hook-form";
import { Link, Routes, Route, useHistory, useLocation } from "react-router-dom";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Row,
  Col,
  Form,
  Alert,
  Button,
  Container,
  Modal,
} from "react-bootstrap";

import axios from "axios";

import AADService from "../../auth/authFunctions";

import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TextField from "@material-ui/core/TextField";
import TableContainer from "@material-ui/core/TableContainer";

import Select from "react-select";
import makeAnimated from "react-select/animated";

import { FormSearchListCompania } from "../../form-components/FormSearchListCompania";
import { FormInputRiskAnalist } from "../../form-components/Plantel/FormInputRiskAnalist";
import { FormInputAreasCorporativo } from "../../form-components/Areas/FormInputAreasCorporativo";
import { FormInputPeriodicidad } from "../../form-components/Tiempo/FormInputPeriodicidad";

import ModalUmbral from "./Modales/ModalUmbral";
import ModalBuscador from "./Modales/ModalBuscador";
import ModalVariables from "./Modales/ModalVariables";
import ModalSeguimiento from "./Modales/ModalSeguimiento";
import ModalSeguimientoLAFT from "./Modales/ModalSeguimientoLAFT";
import ModalAsociarVariable from "./Modales/ModalAsociarVariable";

const animatedComponents = makeAnimated();

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",
    color: theme.palette.common.white,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
    heigth: "10px",
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    width: "100%",
    zIndex: 0,
  },
  container: {
    /* maxHeight: "60vh", */
    minHeight: "20vh",
  },

  MuiTableRow: {
    root: {
      //This can be referred from Material UI API documentation.
      heigth: "10px",
    },
  },
});

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;

    case 2:
      return <Alert variant="success">Guardó exitosamente</Alert>;

    case 3:
      return <Alert variant="danger"></Alert>;

    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;

    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;

    case 7:
      return (
        <Alert variant="warning">
          Corrige los siguientes errores:
          <br></br>• Debe completar los campos obligatorios
        </Alert>
      );

    default:
      return <p></p>;
  }
}

export default function EditarIndicador() {
  const serviceAAD = new AADService();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [estadoPost, setEstadoPost] = useState({
    alerta: { id: 0, data: null },
  });

  const [valor, setValor] = React.useState(0);

  const [id, setID] = useState(null);
  const [loadingData, setLoadingData] = useState(null);

  const [listaCompanias, setListaCompanias] = useState([]);
  const [listaResponables, setListaResponables] = useState([]);
  const [listaSegmentos, setListaSegmentos] = useState([]);
  const [elementoId, setElementoId] = useState([]);

  const [showVariables, setShowVariables] = useState(false);
  const [showUmbrales, setShowUmbrales] = useState(false);
  const [showSeguimiento, setShowSeguimiento] = useState(false);
  const [showSeguimientoLAFT, setShowSeguimientoLAFT] = useState(false);
  const [showBuscador, setShowBuscador] = useState(false);
  const [showAsociarVariable, setShowAsociarVariable] = useState(false);

  const [dataVariables, setDataVariables] = useState([]);
  const [dataUmbrales, setDataUmbrales] = useState([]);
  const [dataSeguimiento, setDataSeguimiento] = useState([]);
  const [dataSeguimientoLAFT, setDataSeguimientoLAFT] = useState([]);
  const [dataBuscador, setDataBuscador] = useState([]);

  const [isEditingVariables, setIsEditingVariables] = useState(false);
  const [isEditingUmbrales, setIsEditingUmbrales] = useState(false);
  const [isEditingSeguimiento, setIsEditingSeguimiento] = useState(false);
  const [isEditingSeguimientoLAFT, setIsEditingSeguimientoLAFT] =
    useState(false);
  const [isEditingBuscador, setIsEditingBuscador] = useState(false);

  const [selectedVariables, setSelectedVariables] = useState([]);
  const [selectedUmbrales, setSelectedUmbrales] = useState([]);
  const [selectedSeguimiento, setSelectedSeguimiento] = useState([]);
  const [selectedSeguimientoLAFT, setSelectedSeguimientoLAFT] = useState([]);
  const [selectedBuscador, setSelectedBuscador] = useState([]);

  const isSelectedVariables = (name) => selectedVariables.indexOf(name) !== -1;
  const isSelectedUmbrales = (name) => selectedUmbrales.indexOf(name) !== -1;
  const isSelectedSeguimiento = (name) =>
    selectedSeguimiento.indexOf(name) !== -1;
  const isSelectedSeguimientoLAFT = (name) =>
    selectedSeguimientoLAFT.indexOf(name) !== -1;

  const handleClickVariables = (event, name) => {
    const selectedIndex = selectedVariables.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], name);
      //SetButtonEdit(true);
    } else {
      //SetButtonEdit(false);
    }

    setSelectedVariables(newSelected);
  };

  const handleClickSeguimiento = (event, name) => {
    const selectedIndex = selectedSeguimiento.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], name);
      //SetButtonEdit(true);
    } else {
      //SetButtonEdit(false);
    }

    setSelectedSeguimiento(newSelected);
  };

  const handleClickUmbrales = (event, name) => {
    const selectedIndex = selectedUmbrales.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], name);
      //SetButtonEdit(true);
    } else {
      //SetButtonEdit(false);
    }

    setSelectedUmbrales(newSelected);
  };

  const DesasociarVariables = () => {
    if (selectedVariables[0]) {
      const selectedData = dataVariables.filter(
        (item) => item.idvariable !== selectedVariables[0]
      );
      setDataVariables(selectedData);
      setSelectedVariables([]);
    } else {
    }
  };

  const defaultValues = {
    id: null,
    estado: null,
    nombre: null,
    descripcion: null,
    compania: null,
    responsable: null,
    ubicacion: null,
    idUbicacion: null,
    periodicidad: null,
    segmento: null,
    formula: null,
    unidad: null,
    interpretacion: null,
  };

  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = methods;

  const llenarFormulario = async (id) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };

    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/indicadores/" + id,
        config
      );

      let data = response.data;

      console.log("Datos del back", data);

      //---------------------LLenado de compañias ------------------------

      const dataCompanias = await axios.get(
        process.env.REACT_APP_API_URL + "/maestros_ro/compania/",
        config
      );

      let listCompanias = dataCompanias.data;

      let listCompaniasMap = listCompanias.map(
        ({ idcompania: value, compania: label, pais }) => ({
          value,
          label,
          pais,
        })
      );

      setListaCompanias(listCompaniasMap);

      let arrayCompanias = data.compania.split(",");

      let temp = [];

      listCompaniasMap.forEach((objCompania) => {
        if (
          arrayCompanias.some(
            (strCompania) => objCompania.label === strCompania
          )
        ) {
          temp.push(objCompania);
        }
      });

      console.log(temp);

      setValue("compania", temp);

      //---------------------------------------------------------

      setValue("nombre", data.nombre);
      setValue("descripcion", data.descripcion);

      //--------------------------------Responsable---------------------------

      let APIS = [
        fetch(process.env.REACT_APP_API_URL + "/usuariosrol/0/4", config),
        fetch(process.env.REACT_APP_API_URL + "/usuariosrol/0/2", config),
        fetch(process.env.REACT_APP_API_URL + "/usuariosrol/0/1", config),
        //fetch(process.env.REACT_APP_API_URL + "/segmentos/", config),
      ];

      Promise.all(APIS)
        .then(async ([riskAnalyst, riskManager, administrador]) => {
          const rAnalist = await riskAnalyst.json();
          const rManager = await riskManager.json();
          const admin = await administrador.json();
          let listRiskAnalist = rAnalist.map(
            ({
              idusuario: value,
              nombre: label,

              n1_vicepresidencia_corporativa,
              area_organizacional,
            }) => ({
              value,
              label,

              n1_vicepresidencia_corporativa,
              area_organizacional,
            })
          );
          let listRiskManager = rManager.map(
            ({
              idusuario: value,
              nombre: label,

              n1_vicepresidencia_corporativa,
              area_organizacional,
            }) => ({
              value,
              label,

              n1_vicepresidencia_corporativa,
              area_organizacional,
            })
          );
          let listAdministrador = admin.map(
            ({
              idusuario: value,
              nombre: label,

              n1_vicepresidencia_corporativa,
              area_organizacional,
            }) => ({
              value,
              label,

              n1_vicepresidencia_corporativa,
              area_organizacional,
            })
          );

          let listExpertos = listRiskAnalist.concat(
            listRiskManager,
            listAdministrador
          );

          setListaResponables(listExpertos);

          let responsableSelected = listExpertos.filter(
            (analista) => analista.label === data.responsable
          );

          console.log(responsableSelected);

          setValue("responsable", responsableSelected[0]);

          setValue(
            "vicepresidencia",
            responsableSelected[0].n1_vicepresidencia_corporativa
          );

          setValue("area", responsableSelected[0].area_organizacional);
        })
        .catch((error) => {
          console.log(error);
        });

      //---------------------------------------------------------

      setValue("ubicacion", { value: data.ubicacion, label: data.ubicacion });
      setValue("idUbicacion", data.nombre_causa);
      setElementoId({ idcausa: data.idelemento});
      //-------------------------Segmentos-------------------------

      let responseCausaSelected = await axios.get(
        process.env.REACT_APP_API_URL + "/causas/" + data.idelemento,
        config
      );

      let listSegmentos = responseCausaSelected.data.segmentos;

      let listSegmentosMap = listSegmentos.map(
        ({ idsegmentos: value, nombre: label, cluster, descripcion }) => ({
          value,
          label,
          cluster,
          descripcion,
        })
      );

      setListaSegmentos(listSegmentosMap);

      let segmentoSelected = listSegmentosMap.filter(
        (segmento) => segmento.value === parseInt(data.idelemento_sec)
      );

      setValue("segmento", segmentoSelected);

      //-------------------------------------------------------

      setValue("periodicidad", {
        value: data.periodicidad,
        label: data.periodicidad,
      });
      setValue("formula", data.formula);
      setValue("unidad", { value: data.tipo_unidad, label: data.tipo_unidad });
      setValue("interpretacion", {
        value: data.interpretacion,
        label: data.interpretacion,
      });

      setDataUmbrales(data.umbrales);

      let datVariables = data.variables;
      setDataVariables(datVariables);

      let tempDataSeguimiento = data.seguimientos;
      setDataSeguimiento(data.seguimientos);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    //---------------------------------------------------------Manejo de ids...
    console.log("Ubicación de donde provengo : ", location);

    if (typeof location.state != "undefined") {
      if (location.state.idindicador) {
        let id = location.state.idindicador;
        setID(id);
        setValue("id", id);
        llenarFormulario(id);
      }
    } else {
      alert("Ups, ocurrió un error, trata de recargar la página");
    }
  }, []);

  const onSubmit = (data) => {
    console.log("Datos recopilados:", data);

    let stringCompanias = data.compania.map((obj) => obj.label).join(",");

    console.log(stringCompanias);

    let arrayVariables = dataVariables.map((obj) => {
      return obj.idvariable;
    });

    let arrayUmbrales = dataUmbrales.map((obj) => {
      return obj.idumbral;
    });

    let arraySeguimiento = dataSeguimiento.map((obj) => {
      return obj.idseguimiento_indicador;
    });

    var datosEnviar = {
      idindicador: data.id,
      nombre: data.nombre,
      descripcion: data.descripcion,
      compania: data.compania ? stringCompanias : null,
      responsable: data.responsable?.label,
      ubicacion: data.ubicacion?.label,
      idelemento: elementoId.idcausa ? elementoId.idcausa : null,
      idelemento_sec: data.segmento?.value,
      periodicidad: data.periodicidad?.label,

      formula: data.formula,
      tipo_unidad: data.unidad?.label,
      interpretacion: data.interpretacion?.label,

      variables: arrayVariables ? arrayVariables : null,
      umbrales: arrayUmbrales ? arrayUmbrales : null,
      seguimientos: arraySeguimiento ? arraySeguimiento : null,
    };

    console.log("JSON a enviar:", datosEnviar);

    try {
      axios
        .put(
          process.env.REACT_APP_API_URL + "/indicadores/" + data.id,
          datosEnviar,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + serviceAAD.getToken(),
            },
          }
        )
        .then(function (response) {
          if (response.status >= 200 && response.status < 300) {
            //setEstadoPost(2);
            console.log(response);
            alert("Guardado con éxito");
            // history.push({
            //   pathname: "/EditarIndicador",
            //   state: { idCausa: response.data.idindicador },
            // });
          } else if (response.status >= 300 && response.status < 400) {
            //setEstadoPost(4);
            alert("Error en el servidor");
          } else if (response.status >= 400 && response.status < 512) {
            //setEstadoPost(5);
            alert("Error en el servidor");
          }
        })
        .catch((errors) => {
          console.log("CHATCH", errors.response.data);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const onError = (errors) => console.log(errors);

  return (
    <>
      <AlertDismissibleExample alerta={estadoPost} />
      <Container fluid>
        <FormProvider {...methods}>
          {/* <----------------------------------------Modales----------------------------------------> */}

          <ModalAsociarVariable
            show={showAsociarVariable}
            onHide={() => {
              setShowAsociarVariable(false);
            }}
            data={dataVariables}
            setData={setDataVariables}
          />

          <ModalBuscador
            show={showBuscador}
            onHide={() => {
              setShowBuscador(false);
              setIsEditingBuscador(false);
              setSelectedBuscador([]);
            }}
            isEditing={isEditingBuscador}
            data={dataBuscador}
            setData={setDataBuscador}
            selected={selectedBuscador}
            setListaSegmentos={setListaSegmentos}
            setElementoId ={setElementoId}
          />

          <ModalVariables
            show={showVariables}
            onHide={() => {
              setShowVariables(false);
              setIsEditingVariables(false);
              setSelectedVariables([]);
            }}
            isEditing={isEditingVariables}
            data={dataVariables}
            setData={setDataVariables}
            selected={selectedVariables}
          />

          <ModalSeguimiento
            show={showSeguimiento}
            onHide={() => {
              setShowSeguimiento(false);
              setIsEditingSeguimiento(false);
              setSelectedSeguimiento([]);
            }}
            isEditing={isEditingSeguimiento}
            data={dataSeguimiento}
            setData={setDataSeguimiento}
            selected={selectedSeguimiento}
            dataVariables={dataVariables}
          />

          <ModalUmbral
            show={showUmbrales}
            onHide={() => {
              setShowUmbrales(false);
              setIsEditingUmbrales(false);
              setSelectedUmbrales([]);
            }}
            isEditing={isEditingUmbrales}
            data={dataUmbrales}
            setData={setDataUmbrales}
            selected={selectedUmbrales}
          />
          {/* <----------------------------------------Titulo----------------------------------------> */}

          <Row className="mb-3 mt-3">
            <Col sm={8} xs={12}>
              <h1 className="titulo">Editar Indicador</h1>
            </Col>

            <Col sm={2} xs={12}>
              <Link to="Indicadores">
                <Button type="button" className="botonNegativo">
                  Cancelar
                </Button>
              </Link>
            </Col>

            <Col sm={2} xs={12}>
              <Button
                type="submit"
                onClick={handleSubmit(onSubmit, onError)}
                variant={"contained"}
                className="btn botonPositivo"
              >
                Guardar
              </Button>
            </Col>
          </Row>
          <hr />
          {/* <----------------------------------------Formulario----------------------------------------> */}
          <Row className="mb-4">
            <Col sm={2} xs={12}>
              <label className="forn-label label">ID</label>
            </Col>
            <Col sm={4} xs={12}>
              <input
                {...register("id")}
                disabled
                type="text"
                className="form-control text-center texto"
                placeholder="ID"
              />
            </Col>

            {/* <Col sm={2} xs={12}>
              <label className="forn-label label">Estado</label>
            </Col>
            <Col sm={2} xs={12}>
              <input
                {...register("estado")}
                disabled
                type="text"
                className="form-control text-center texto"
                placeholder="Nuevo Estado del evento"
              />
            </Col> */}
          </Row>

          <Row className="mb-4">
            <Col sm={2} xs={12}>
              <label className="forn-label label">Nombre*</label>
            </Col>

            <Col sm={10} xs={12}>
              <input
                {...register("nombre", {
                  required: "Te faltó completar este campo",
                })}
                type="text"
                className="form-control text-center texto"
                placeholder="Nombre del Indicador"
              />
              <p className="text-center">{errors.nombre?.message}</p>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={2} xs={12}>
              <label className="forn-label label">Descripción*</label>
            </Col>

            <Col sm={10} xs={12}>
              <textarea
                {...register("descripcion", {
                  required: "Te faltó completar este campo",
                })}
                className="form-control text-center"
                placeholder="Descripción del Indicador"
                rows="3"
              />
              <p className="text-center">{errors.descripcion?.message}</p>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={2} xs={12}>
              <label className="forn-label label">Compañía*</label>
            </Col>
            <Col sm={10} xs={12}>
              <Controller
                control={control}
                name="compania"
                rules={{ required: "Te faltó completar este campo" }}
                render={({ field }) => (
                  <Select
                    isMulti
                    components={animatedComponents}
                    placeholder="Seleccione las compañias"
                    options={listaCompanias}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                )}
              />
              <p className="text-center">{errors.compania?.message}</p>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={2} xs={12}>
              <label className="forn-label label">Responsable*</label>
            </Col>

            <Col sm={4} xs={12}>
              <Controller
                control={control}
                name="responsable"
                rules={{ required: "Te faltó completar este campo" }}
                render={({ field }) => (
                  <Select
                    components={animatedComponents}
                    placeholder="Seleccione el Responsable"
                    options={listaResponables}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                      setValue(
                        "vicepresidencia",
                        e.n1_vicepresidencia_corporativa
                      );
                      setValue("area", e.area_organizacional);
                    }}
                  />
                )}
              />
              <p className="text-center">{errors.responsable?.message}</p>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={2} xs={12}>
              <label className="forn-label label">Vicepresidencia</label>
            </Col>
            <Col sm={4} xs={12}>
              <input
                {...register("vicepresidencia", {
                  required: false,
                })}
                type="text"
                disabled={true}
                className="form-control text-center texto"
                placeholder="Vicepresidencia"
              />
              <p className="text-center">{errors.vicepresidencia?.message}</p>
            </Col>
            <Col sm={2} xs={12}>
              <label className="forn-label label">Área</label>
            </Col>
            <Col sm={4} xs={12}>
              <input
                {...register("area", {
                  required: false,
                })}
                type="text"
                disabled={true}
                className="form-control text-center texto"
                placeholder="Área"
              />
              <p className="text-center">{errors.area?.message}</p>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={2} xs={12}>
              <label className="forn-label label">Ubicación*</label>
            </Col>

            <Col sm={4} xs={12}>
              <Controller
                control={control}
                name="ubicacion"
                rules={{ required: "Te faltó completar este campo" }}
                render={({ field }) => (
                  <Select
                    components={animatedComponents}
                    placeholder="Seleccione la Ubicación"
                    options={[
                      //{ value: 0, label: "Riesgo" },
                      // { value: 1, label: "Control" },
                      { value: 2, label: "Causa" },
                      //{ value: 3, label: "Efecto" },
                    ]}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                      setShowBuscador(true);
                    }}
                  />
                )}
              />

              <p className="text-center">{errors.ubicacion?.message}</p>
            </Col>

            <Col sm={2} xs={12}>
              <label className="forn-label label">Elemento*</label>
            </Col>

            <Col sm={4} xs={12}>
              <input
                {...register("idUbicacion", {
                  required: "Te faltó completar este campo",
                })}
                type="text"
                disabled={true}
                className="form-control text-center texto"
                placeholder="Archivo seleccionado"
              />
              <p className="text-center">{errors.idUbicacion?.message}</p>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={2} xs={12}>
              <label className="forn-label label">Periodicidad*</label>
            </Col>
            <Col sm={4} xs={12}>
              <FormInputPeriodicidad
                control={control}
                name={"periodicidad"}
                label={"Seleccione la Periodicidad"}
              />
              <p className="text-center">{errors.periodicidad?.message}</p>
            </Col>

            <Col sm={2} xs={12}>
              <label className="forn-label label">Segmento</label>
            </Col>
            <Col sm={4} xs={12}>
              <Controller
                control={control}
                name="segmento"
                rules={{ required: false }}
                render={({ field }) => (
                  <Select
                    components={animatedComponents}
                    placeholder="Seleccione el segmento"
                    options={listaSegmentos}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                )}
              />
              <p className="text-center">{errors.segmento?.message}</p>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={2} xs={12}>
              <label className="forn-label label">Formula*</label>
            </Col>

            <Col sm={10} xs={12}>
              <input
                {...register("formula", {
                  required: "Te faltó completar este campo",
                })}
                type="text"
                className="form-control text-center texto"
                placeholder="Ingrese la fórmula"
              />
              <p className="text-center">{errors.formula?.message}</p>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col sm={2} xs={12}>
              <label className="forn-label label">Unidad de medición*</label>
            </Col>
            <Col sm={4} xs={12}>
              <Controller
                control={control}
                name="unidad"
                rules={{ required: "Te faltó completar este campo" }}
                render={({ field }) => (
                  <Select
                    components={animatedComponents}
                    placeholder="Seleccione la Unidad"
                    options={[
                      { value: 0, label: "Porcentaje" },
                      { value: 1, label: "Personas" },
                      { value: 2, label: "COP" },
                      { value: 3, label: "USD" },
                    ]}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                )}
              />
              <p className="text-center">{errors.unidad?.message}</p>
            </Col>

            <Col sm={2} xs={12}>
              <label className="forn-label label">Interptetación*</label>
            </Col>

            <Col sm={4} xs={12}>
              <Controller
                control={control}
                name="interpretacion"
                rules={{ required: "Te faltó completar este campo" }}
                render={({ field }) => (
                  <Select
                    components={animatedComponents}
                    placeholder=""
                    options={[
                      { value: 0, label: "Bueno cuando sube" },
                      { value: 1, label: "Bueno cuando baja" },
                    ]}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                  />
                )}
              />
              <p className="text-center">{errors.interpretacion?.message}</p>
            </Col>
          </Row>

          <br />

          {/* <---------------------------------------- Modal  Variables----------------------------------------> */}
          <Row className="mb-3">
            <Col sm={4} xs={12}>
              <label className="form-label label">Variables</label>
            </Col>

            <Col sm={2} xs={12}>
              {selectedVariables[0] ? (
                <button
                  type="button"
                  className="btn botonNegativo"
                  onClick={() => {
                    setIsEditingVariables(true);
                    setShowVariables(true);
                  }}
                >
                  Editar
                </button>
              ) : (
                <></>
              )}
            </Col>
            <Col sm={2} xs={12}>
              <button
                type="button"
                className="btn botonPositivo"
                onClick={setShowVariables}
              >
                Crear
              </button>
            </Col>
            <Col sm={2} xs={12}>
              <button
                type="button"
                className="btn botonPositivo"
                onClick={setShowAsociarVariable}
              >
                Asociar
              </button>
            </Col>
            <Col sm={2} xs={12}>
              {selectedVariables[0] ? (
                <button
                  type="button"
                  className="btn botonNegativo"
                  onClick={DesasociarVariables}
                >
                  Desasociar
                </button>
              ) : (
                <></>
              )}
            </Col>
          </Row>

          <Row className="mb-4">
            <Paper className={classes.root}>
              <TableContainer component={Paper} className={classes.container}>
                <Table
                  className={"text"}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead className="titulo">
                    <TableRow>
                      <StyledTableCell padding="checkbox"></StyledTableCell>

                      <StyledTableCell align="left">ID</StyledTableCell>

                      <StyledTableCell align="left">Compañia</StyledTableCell>

                      <StyledTableCell align="left">Nombre</StyledTableCell>

                      <StyledTableCell align="left">
                        Descripción
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        Responsable
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {/* Fin de encabezado */}
                  {/* Inicio de cuerpo de la tabla */}
                  <TableBody>
                    {dataVariables.map((row, index) => {
                      const isItemSelected = isSelectedVariables(
                        row.idvariable
                      );
                      return (
                        <StyledTableRow
                          key={row.idvariable}
                          hover
                          onClick={(event) =>
                            handleClickVariables(event, row.idvariable)
                          }
                          selected={isItemSelected}
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StyledTableCell component="th" scope="row">
                            <Checkbox checked={isItemSelected} />
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.idvariable}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.compania ? row.compania : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.nombre ? row.nombre : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.descripcion ? row.descripcion : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.responsable ? row.responsable : null}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                  {/* Fin de cuerpo de la tabla */}
                </Table>
              </TableContainer>
            </Paper>
          </Row>
          {/* <--------------------------------------------------------------------------------> */}

          <br />
          {/* <---------------------------------------- Modal Umbral----------------------------------------> */}
          <Row className="mb-3">
            <Col sm={4} xs={12}>
              <label className="form-label label">Umbrales</label>
            </Col>

            <Col sm={2} xs={12}>
              {selectedUmbrales[0] ? (
                <button
                  type="button"
                  className="btn botonNegativo"
                  onClick={() => {
                    setIsEditingUmbrales(true);
                    setShowUmbrales(true);
                  }}
                >
                  Editar
                </button>
              ) : (
                <></>
              )}
            </Col>
            {dataUmbrales.length === 0 ? (
            <Col sm={2} xs={12}>
              <button
                type="button"
                className="btn botonPositivo"
                onClick={setShowUmbrales}
              >
                Crear
              </button>
            </Col>
            ) : (
              <></>
            )}
            <Col sm={2} xs={12}>
              {/* <button
                type="button"
                className="btn botonPositivo"
                onClick={setShowUmbrales}
              >
                Asociar
              </button> */}
            </Col>
            <Col sm={2} xs={12}>
              {/* {selectedUmbrales[0] ? (
                <button
                  type="button"
                  className="btn botonNegativo"
                  onClick={DesasociarSegmento}
                >
                  Desasociar
                </button>
              ) : (
                <></>
              )} */}
            </Col>
          </Row>

          <Row className="mb-4">
            <Paper className={classes.root}>
              <TableContainer component={Paper} className={classes.container}>
                <Table
                  className={"text"}
                  stickyHeader
                  aria-label="sticky table"
                >
                  {/* Inicio de encabezado */}
                  <TableHead className="titulo">
                    <TableRow>
                      <StyledTableCell padding="checkbox"></StyledTableCell>

                      <StyledTableCell align="left">ID</StyledTableCell>

                      <StyledTableCell align="left">
                        Límite inferior
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        Límite superior
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  {/* Fin de encabezado */}
                  {/* Inicio de cuerpo de la tabla */}
                  <TableBody>
                    {dataUmbrales.map((row, index) => {
                      const isItemSelected = isSelectedUmbrales(row.idumbral);
                      return (
                        <StyledTableRow
                          key={row.idumbral}
                          hover
                          onClick={(event) =>
                            handleClickUmbrales(event, row.idumbral)
                          }
                          selected={isItemSelected}
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StyledTableCell component="th" scope="row">
                            <Checkbox checked={isItemSelected} />
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.idumbral}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.limite_inferior
                                ? parseFloat(row.limite_inferior).toFixed(6)
                                : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.limite_superior ? 
                              parseFloat(row.limite_superior).toFixed(6) 
                              : null}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                  {/* Fin de cuerpo de la tabla */}
                </Table>
              </TableContainer>
            </Paper>
          </Row>
          {/* <---------------------------------------Modal  seguimiento LAFT-----------------------------------------> */}
          <br />

          {/* <Row className="mb-3">
            <Col sm={4} xs={12}>
              <label className="form-label label">Seguimiento LAFT</label>
            </Col>

            <Col sm={2} xs={12}>
              {selectedVariables[0] ? (
                <button
                  type="button"
                  className="btn botonNegativo"
                  //onClick={() => {}}
                >
                  Editar
                </button>
              ) : (
                <></>
              )}
            </Col>
            <Col sm={2} xs={12}>
              <button
                type="button"
                className="btn botonPositivo"
                onClick={setShowSeguimientoLAFT}
              >
                Crear
              </button>
            </Col>
            <Col sm={2} xs={12}>
              <button
                type="button"
                className="btn botonPositivo"
                //onClick={setShowSeguimientoLAFT }
              >
                Asociar
              </button>
            </Col>
            <Col sm={2} xs={12}>
              {selectedSeguimiento[0] ? (
                <button
                  type="button"
                  className="btn botonNegativo"
                  //onClick={DesasociarSeguimientoLAFT }
                >
                  Desasociar
                </button>
              ) : (
                <></>
              )}
            </Col>
          </Row>

          <Row className="mb-4">
            <Paper className={classes.root}>
              <TableContainer component={Paper} className={classes.container}>
                <Table
                  className={"text"}
                  stickyHeader
                  aria-label="sticky table"
                >
                  
                  <TableHead className="titulo">
                    <TableRow>
                      <StyledTableCell padding="checkbox"></StyledTableCell>

                      <StyledTableCell align="left">Periodo</StyledTableCell>

                      <StyledTableCell align="left">Segmento</StyledTableCell>

                      <StyledTableCell align="left">Variable 1</StyledTableCell>

                      <StyledTableCell align="left">Variable 2</StyledTableCell>

                      <StyledTableCell align="left">
                        Valor Indicador
                      </StyledTableCell>

                      <StyledTableCell align="left">
                        Resultado probabilidad
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
               
                  <TableBody>
                    {dataSeguimientoLAFT.map((row, index) => {
                      const isItemSelected = isSelectedSeguimientoLAFT(
                        row.idsegmentos
                      );
                      return (
                        <StyledTableRow
                          key={row.idsegmentos}
                          hover
                          onClick={(event) =>
                            handleClickSeguimientoLAFT(event, row.idsegmentos)
                          }
                          selected={isItemSelected}
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StyledTableCell component="th" scope="row">
                            <Checkbox checked={isItemSelected} />
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.idsegmentos}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {row.compania ? row.compania : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.factor ? row.factor : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.segmento_a_priori
                              ? row.segmento_a_priori
                              : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.cluster ? row.cluster : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.descripcion ? row.descripcion : null}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
           
                </Table>
              </TableContainer>
            </Paper>
          </Row> */}

          <br />
          {/* <---------------------------------------- Modal  seguimiento----------------------------------------> */}
          <Row className="mb-3">
            <Col sm={4} xs={12}>
              <label className="form-label label">
                Seguimiento de indicador
              </label>
            </Col>

            <Col sm={2} xs={12}>
              {selectedSeguimiento[0] ? (
                <button
                  type="button"
                  className="btn botonNegativo"
                  onClick={() => {
                    setIsEditingSeguimiento(true);
                    setShowSeguimiento(true);
                  }}
                >
                  Editar
                </button>
              ) : (
                <></>
              )}
            </Col>
            <Col sm={2} xs={12}>
              <button
                type="button"
                className="btn botonPositivo"
                onClick={setShowSeguimiento}
              >
                Crear
              </button>
            </Col>
            <Col sm={2} xs={12}>
              {/* <button
                type="button"
                className="btn botonPositivo"
                //onClick={setShowSeguimiento}
              >
                Asociar
              </button> */}
            </Col>
            <Col sm={2} xs={12}>
              {/* {selectedSeguimiento[0] ? (
                <button
                  type="button"
                  className="btn botonNegativo"
                  //onClick={DesasociarSegmento}
                >
                  Desasociar
                </button>
              ) : (
                <></>
              )} */}
            </Col>
          </Row>

          <Row className="mb-4">
            <Paper className={classes.root}>
              <TableContainer component={Paper} className={classes.container}>
                <Table
                  className={"text"}
                  stickyHeader
                  aria-label="sticky table"
                >
                  {/* Inicio de encabezado */}
                  <TableHead className="titulo">
                    <TableRow>
                      <StyledTableCell padding="checkbox"></StyledTableCell>
                      <StyledTableCell align="left">ID</StyledTableCell>

                      <StyledTableCell align="left">Periodo</StyledTableCell>

                      <StyledTableCell align="left">Variable 1</StyledTableCell>

                      <StyledTableCell align="left">Variable 2</StyledTableCell>

                      <StyledTableCell align="left">
                        Valor Indicador
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {dataSeguimiento.map((row, index) => {
                      const isItemSelected = isSelectedSeguimiento(
                        row.idseguimiento_indicador
                      );
                      return (
                        <StyledTableRow
                          key={row.idseguimiento_indicador}
                          hover
                          onClick={(event) =>
                            handleClickSeguimiento(
                              event,
                              row.idseguimiento_indicador
                            )
                          }
                          selected={isItemSelected}
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StyledTableCell component="th" scope="row">
                            <Checkbox checked={isItemSelected} />
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {row.idseguimiento_indicador
                              ? row.idseguimiento_indicador
                              : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.periodo ? row.periodo : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.variable_1 ? 
                            parseFloat(row.variable_1).toFixed(6) 
                            : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.variable_2 ?
                            parseFloat(row.variable_2).toFixed(6)
                            : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.valor_indicador ?
                            parseFloat(row.valor_indicador).toFixed(6)
                            : null}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Row>
          {/* <--------------------------------------------------------------------------------> */}
          <br />
        </FormProvider>
      </Container>
    </>
  );
}
