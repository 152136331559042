import React, { useState, useEffect, useContext } from "react";

import { Button, Row, Col, Form, Container, Modal } from "react-bootstrap";

import { useFormContext } from "react-hook-form";

import AADService from "../../../auth/authFunctions";

import axios from "axios";
import Loader from "react-loader-spinner";

import { withStyles, makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";
import { elementType } from "prop-types";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",
    color: theme.palette.common.white,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
}))(TableRow);
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "60vh",
    minHeight: "60vh",
  },
});

export default function ModalAsociarControl(props) {
  const serviceAAD = new AADService();
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = React.useState(false);

  const [page, setPage] = React.useState(0);
  const [selected, setSelected] = React.useState([]);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [buscando, setBuscando] = React.useState("");
  const [dataBusqueda, setDataBusqueda] = React.useState([]);

  const { getValues } = useFormContext();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (index) => {
    if (dataBusqueda[index].checked === true) {
      dataBusqueda[index].checked = false;
      setSelected(
        selected.filter((item) => item !== dataBusqueda[index].idcontrol)
      );
    } else {
      dataBusqueda[index].checked = true;
      setSelected([...selected, dataBusqueda[index].idcontrol]);
    }
  };

  const Asociar = () => {
    if (selected.length > 0) {
      const selectedData = dataBusqueda.filter((control) =>
        selected.some((item) => item === control.idcontrol)
      );

      console.log(selectedData);
      //Si el elemento seleccionado tiene estado 0 o " " no se puede asociar
      if (selectedData.some((data) => data.estado === 0) || selectedData.some((data) => data.estado === " ")) {
        alert("No se pueden asociar elementos inactivos");
      } else {
        props.setDataControles([...props.dataControles, ...selectedData]);
      }
      setSelected([]);
      props.onHide();
      props.setShowCubrimiento(true);
    }
  };

  function buscar(e) {
    setBuscando(e.target.value);

    const texto = e.target.value.toLowerCase();

    const lowCaseArray = data.filter(
      (item) =>
        String(item.nombre).toLowerCase().includes(texto) ||
        String(item.descripcion).toLowerCase().includes(texto) ||
        String(item.compania).toLowerCase().includes(texto) ||
        String(item.proceso).toLowerCase().includes(texto) ||
        String(item.idcontrol).includes(texto)
    );

    setDataBusqueda(lowCaseArray);
  }

  useEffect(() => {
    setLoadingData(true);
    try {
      axios
        .get(process.env.REACT_APP_API_URL + "/controles_laft/", {
          headers: {
            Authorization: "Bearer " + serviceAAD.getToken(),
          },
        })
        .then((response) => {
          let data = response.data;

          const company = getValues("compania");
          console.log(company);

          if (company === null && props.show === true) {
            props.onHide();
            alert("Debe seleccionar una compañia");
            return;
          }

          let stringCompania = company.label;

          let list = data.filter((control) => {
            let arrayCompaniasAplica = control.companias_aplica.split(",");
            let companiaAplica = arrayCompaniasAplica.some(
              (compania) => compania === stringCompania
            );
            return (
              control.compania === stringCompania ||
              (companiaAplica && control.varias_companias === "Si")
            );
          });

          if (Array.isArray(list) && !list.length && props.show) {
            setData([]);
            alert("No hay registros para la compañia seleccionada");
            props.onHide();
          } else {
            let filteredlist = list.filter(
              (element) =>
                !props.dataControles.some(
                  (item) => item.idcontrol === element.idcontrol
                )
            );

            const checkList = filteredlist.map((obj) => ({
              ...obj,
              checked: false,
            }));

            setData(checkList);
            setDataBusqueda(checkList);

            setLoadingData(false);
          }
        });
    } catch (error) {
      console.error(error);
    }
  }, [props.show]);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Col sm={4} xs={12}>
          <Modal.Title id="contained-modal-title-vcenter">
            Asociar Control
          </Modal.Title>
        </Col>

        <Col sm={4} xs={12}>
          <Form>
            <Form.Control
              value={buscando}
              onChange={(e) => buscar(e)}
              type="text"
              placeholder="Buscar"
            />
          </Form>
        </Col>
        <Col sm={2} xs={12}>
          <button type="button" className="btn botonPositivo" onClick={Asociar}>
            Asociar
          </button>
        </Col>

        <Col sm={2} xs={12}>
          <button
            type="button"
            className="btn botonNegativo"
            onClick={props.onHide}
          >
            Cancelar
          </button>
        </Col>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container fluid>
          <Row className="mb-3 mt-3"></Row>

          <Paper className={classes.root}>
            <TableContainer component={Paper} className={classes.container}>
              <Table className={"text"} stickyHeader aria-label="sticky table">
                {/* Inicio de encabezado */}
                <TableHead className="titulo">
                  <TableRow>
                    <StyledTableCell padding="checkbox"></StyledTableCell>
                    <StyledTableCell align="left">ID Control</StyledTableCell>
                    <StyledTableCell align="left">Compañía</StyledTableCell>
                    <StyledTableCell align="left">Proceso</StyledTableCell>
                    <StyledTableCell align="left">Nombre</StyledTableCell>
                    <StyledTableCell align="left">Descripción</StyledTableCell>
                    <StyledTableCell align="left">Estado</StyledTableCell>

                    {/* <StyledTableCell align="left">Estado</StyledTableCell> */}
                  </TableRow>
                </TableHead>
                {/* Fin de encabezado */}
                {/* Inicio de cuerpo de la tabla */}
                <TableBody>
                  {dataBusqueda.map((row, index) => {
                    return (
                      <StyledTableRow
                        key={row.idcontrol}
                        hover
                        onClick={() => handleClick(index)}
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <StyledTableCell component="th" scope="row">
                          <Checkbox checked={row.checked} />
                        </StyledTableCell>

                        <StyledTableCell component="th" scope="row">
                          {row.idcontrol ? row.idcontrol : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.compania ? row.compania : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.proceso ? row.proceso : null}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.nombre ? row.nombre : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.descripcion ? row.descripcion : null}
                        </StyledTableCell>
                        
                        <StyledTableCell align="left">
                          {row.estado ? row.estado : null}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
                {/* Fin de cuerpo de la tabla */}
              </Table>
            </TableContainer>
            {/* Inicio de paginación */}
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {/* Fin de paginación */}
          </Paper>
        </Container>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
