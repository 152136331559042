import React, { useEffect, useState, useContext, useCallback, useRef} from "react";
import { useForm, Controller, useWatch, FormProvider } from "react-hook-form";
import { Link, Routes, Route, useHistory, useLocation } from "react-router-dom";

import {
  Row,
  Col,
  Form,
  Alert,
  Button,
  Container,
  Modal,
} from "react-bootstrap";

import axios from "axios";

import AADService from "../../auth/authFunctions";

import { forwardRef } from "react";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import Loader from "react-loader-spinner";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TextField from "@material-ui/core/TextField";
import TableContainer from "@material-ui/core/TableContainer";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import Select from "react-select";
import makeAnimated from "react-select/animated";
import ModalAsociarMacroriesgos from "./Modales/ModalAsociarMacroriesgos";

const animatedComponents = makeAnimated();

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",
    color: theme.palette.common.white,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
    heigth: "10px",
    zIndex: 0,
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    width: "100%",
    zIndex: 0,
  },
  container: {
    /* maxHeight: "60vh", */
    minHeight: "20vh",
  },

  MuiTableRow: {
    root: {
      //This can be referred from Material UI API documentation.
      heigth: "10px",
    },
  },
});

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;

    case 2:
      return <Alert variant="success">Guardó exitosamente</Alert>;

    case 3:
      return <Alert variant="danger"></Alert>;

    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;

    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;

    case 7:
      return (
        <Alert variant="warning">
          Corrige los siguientes errores:
          <br></br>• Debe completar los campos obligatorios
        </Alert>
      );

    default:
      return <p></p>;
  }
}
export default function CrearValoracionLAFT() {    
    let history = useHistory();
    const selectInputRef = useRef();
    const classes = useStyles();
    const serviceAAD = new AADService();
    const location = useLocation();

    const [estadoPost, setEstadoPost] = useState({
        alerta: { id: 0, data: null },
      });


    const [showAsociarMacroriesgo, setShowAsociarMacroriesgo] = useState(false);
    const [dataMacroriesgos, setDataMacroriesgos] = useState([]);
    const [selectedMacroriesgos, setSelectedMacroriesgos] = useState([]);
    const [fechaCorte, setFechaCorte] = useState(null);
    const [dataConsecuencias, setDataConsecuencias] = useState([]);
    const [dataIndicadores, setDataIndicadores] = useState([]);
    const [dataExpertos, setDataExpertos] = useState([]);
    const [metodoImpacto, setMetodoImpacto] = useState(null);
    const [metodoProbabilidad, setMetodoProbabilidad] = useState(null);
    const [impactoData, setImpactoData] = useState(false);
    const [probabilidadData, setProbabilidadData] = useState(false);
    const [selectedIndicadores, setSelectedIndicadores] = useState([]);
    const [selectedExpertos, setSelectedExpertos] = useState([]);
    const [idCausa, setIdCausa] = useState(null);

    const isSelectedMacroriesgos = (name) =>
        selectedMacroriesgos.indexOf(name) !== -1;
    const isSelectedIndicadores = (name) =>
        selectedIndicadores.indexOf(name) !== -1;
    const isSelectedExpertos = (name) => 
        selectedExpertos.indexOf(name) !== -1;
    const handleClickMacroriesgos = (event, name) => {
        const selectedIndex = selectedMacroriesgos.indexOf(name);
    
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat([], name);
          //SetButtonEdit(true);
        } else {
          //SetButtonEdit(false);
        }
    
        setSelectedMacroriesgos(newSelected);
      };

    const handleClickIndicadores = (event, name) => {
        const selectedIndex = selectedIndicadores.indexOf(name);
    
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat([], name);
          //SetButtonEdit(true);
        } else {
          //SetButtonEdit(false);
        }
    
        setSelectedIndicadores(newSelected);
      };
    const handleClickExpertos = (event, name) => {
        const selectedIndex = selectedExpertos.indexOf(name);
    
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat([], name);
          //SetButtonEdit(true);
        } else {
          //SetButtonEdit(false);
        }
    
        setSelectedExpertos(newSelected);
      };
    const DesasociarMacroriesgo = () => {
        if (selectedMacroriesgos[0]) {
          const selectedData = dataMacroriesgos.filter(
            (macroriesgo) => macroriesgo.idcausa !== selectedMacroriesgos[0]
          );
          setDataMacroriesgos(selectedData);
          setSelectedMacroriesgos([]);
          setImpactoData(false);
          setProbabilidadData(false);
          setDataConsecuencias([]);
          setDataIndicadores([]);
          setDataExpertos([]);
          setValue("CalificacionImpacto", "");
          setValue("CalificacionProbabilidadExpertos", "");
          setValue("CalificacionProbabilidadIndicadores", "");
          setValue("riesgoCausaInherenteExpertos", "");
          setValue("riesgoCausaInherenteIndicadores", "");
          setValue("riesgoCausaResidualExpertos", "");
          setValue("riesgoCausaResidualIndicadores", "");
          setMetodoImpacto(null);
          setMetodoProbabilidad(null);
          selectInputRef.current.select.clearValue();

        } else {
        }
      };

    const defaultValues = {

    };


    const methods = useForm({
        defaultValues,
        mode: "onChange",
    });

    const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
    } = methods;


    const [listaSemestres, setListaSemestres] = useState([]);

    const llenarSemestres = () => {
        let fechaActual = new Date();
        let anioActual = fechaActual.getFullYear();
        let mesActual = fechaActual.getMonth();
        let semestreActual = mesActual >= 6 ? 2 : 1;

        let tempListaSemestres = [];

        for (let anio = 2022; anio <= anioActual; anio++) {
        for (let semestre = 1; semestre <= 2; semestre++) {
            if (anio === anioActual && semestre > semestreActual) {
            break;
            }
            tempListaSemestres.push({
            value: String(anio) + "-" + semestre,
            label: String(anio) + "-" + semestre,
            });
        }
        }
        setListaSemestres(tempListaSemestres);
        return tempListaSemestres;
    };
    const ApiFechaCorte = (fecha, data) => {
        console.log("Estoy en la función ApiFechaCorte");
        console.log("Fecha: " + fecha);
        let idcausa = data[0].idcausa;
        console.log("idcausa: " + idcausa);
        setIdCausa(idcausa);
        setFechaCorte(fecha);
        let url = process.env.REACT_APP_API_URL + "/valoracion_laft/";
        let config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + serviceAAD.getToken(),
            },
          };
          try {
            axios.get(url + fecha + "/" + idcausa + "/" ,config)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        let data = response.data;
                        console.log("data", data);
                        setDataConsecuencias(data.consecuencias);
                        setDataIndicadores(data.indicadores);
                        setDataExpertos(data.expertos);
                        setValue("calificacionImpacto", data.calificacion_impacto);
                        setValue("calificacionProbabilidadExpertos", data.calificacion_probabilidad_expertos);
                        setValue("calificacionProbabilidadIndicadores", data.calificacion_probabilidad_indicadores);
                        setValue("riesgoCausaInherenteExpertos", data.riesgo_causa_inherente_expertos);
                        setValue("riesgoCausaInherenteIndicadores", data.riesgo_causa_inherente_indicadores);
                        setValue("riesgoCausaResidualExpertos", data.riesgo_causa_residual_expertos);
                        setValue("riesgoCausaResidualIndicadores", data.riesgo_causa_residual_indicadores);
                        setImpactoData(true)
                        setProbabilidadData(true);
                    }
              //setDataExpertos(response.data.expertosData);
              //setValue("calificacionProbabilidad", response.data.calificacion_probabilidad);
              //sessionStorage.setItem("corte", fecha);
            });
          } catch (error) {
            console.error(error);
          }

    };

    const SelectCausa = () => {
        console.log("Estoy en la función SelectCausa")
        setShowAsociarMacroriesgo(true);
        setImpactoData(false);
        setProbabilidadData(false);
        setDataConsecuencias([]);
        setDataIndicadores([]);
        setDataExpertos([]);
        setValue("CalificacionImpacto", "");
        setValue("CalificacionProbabilidadExpertos", "");
        setValue("CalificacionProbabilidadIndicadores", "");
        setValue("riesgoCausaInherenteExpertos", "");
        setValue("riesgoCausaInherenteIndicadores", "");
        setValue("riesgoCausaResidualExpertos", "");
        setValue("riesgoCausaResidualIndicadores", "");
        setMetodoImpacto(null);
        setMetodoProbabilidad(null);
        selectInputRef.current.select.clearValue();
    };

    const GuardarValoracion = () => {
      console.log("Estoy en la función onSubmit");
      console.log("idcausa: ", idCausa);
      let datosEnviar = {
        idcausa: idCausa ? idCausa : null,
        fecha_corte: fechaCorte ? fechaCorte : null,
        calificacion_impacto: getValues("calificacionImpacto") ? getValues("calificacionImpacto") : null,
        calificacion_probabilidad_expertos: getValues("calificacionProbabilidadExpertos") ? getValues("calificacionProbabilidadExpertos") : null,
        calificacion_probabilidad_indicadores: getValues("calificacionProbabilidadIndicadores") ? getValues("calificacionProbabilidadIndicadores") : null,
        riesgo_causa_inherente_expertos: getValues("riesgoCausaInherenteExpertos") ? getValues("riesgoCausaInherenteExpertos") : null,
        riesgo_causa_inherente_indicadores: getValues("riesgoCausaInherenteIndicadores") ? getValues("riesgoCausaInherenteIndicadores") : null,
        riesgo_causa_residual_expertos: getValues("riesgoCausaResidualExpertos") ? getValues("riesgoCausaResidualExpertos") : null,
        riesgo_causa_residual_indicadores: getValues("riesgoCausaResidualIndicadores") ? getValues("riesgoCausaResidualIndicadores") : null,
        metodo_val_impacto: metodoImpacto.label ? metodoImpacto.label : null,
        metodo_val_probabilidad: metodoProbabilidad.label ? metodoProbabilidad.label : null,
        consecuencias: dataConsecuencias ? dataConsecuencias : [],
        indicadores: dataIndicadores ? dataIndicadores : [],
        expertos: dataExpertos ? dataExpertos : [],
    };

    console.log("datosEnviar", datosEnviar);

    const respuestaUsuario = window.confirm(
      "¿Está seguro de enviar el formulario?, al aceptar ya no podrá modificar su respuesta"
    );

    if (respuestaUsuario) {
      axios
      .put(
        process.env.REACT_APP_API_URL + "/valoracion_laft/" + fechaCorte + "/" + idCausa + "/",
        datosEnviar,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + serviceAAD.getToken(),
          },
        }
      )
      .then(function (response) {
        console.log('response', response);
        if (response.status >= 200 && response.status < 300) {
          alert("Guardado con éxito");
        } else if (response.status >= 300 && response.status < 400) {
          setEstadoPost(4);
          alert("Error en el servidor");
        } else if (response.status >= 400 && response.status < 512) {
          setEstadoPost(5);
          alert("Error en el servidor");
        }
      })
      .catch((error) => {
        if (error.response) {
          alert(error.response.data.message);
        }
      });
    }
    else {
      alert("No se ha enviado el formulario");
    }
  }
    useEffect(() => {

        llenarSemestres();

    }, []);




    const onError = (errors) => console.error(errors);

    return (
    <>
      <AlertDismissibleExample alerta={estadoPost} />
        <Container fluid>
        <FormProvider {...methods}>

        <ModalAsociarMacroriesgos
            show={showAsociarMacroriesgo}
            onHide={() => setShowAsociarMacroriesgo(false)}
            dataMacroriesgos={dataMacroriesgos}
            setDataMacroriesgos={setDataMacroriesgos}
          />


        <Row className="mb-3 mt-3">
            <Col sm={8} xs={12}>
                <h1 className="titulo">Crear Valoración LAFT</h1>
            </Col>
            <Col sm={2} xs={12}>    
                <Link to="ValoracionLAFT">
                    <Button type="button" className="botonNegativo">
                        Cancelar
                    </Button>
                </Link>
            </Col>

            <Col sm={2} xs={12}>
                <Button
                    type="submit"
                    onClick={GuardarValoracion}
                    variant={"contained"}
                    className="btn botonPositivo"
                >
                Guardar
            </Button>
            </Col>
        </Row>

        <hr />

        <Row className="mb-3">
            <Col sm={6} xs={12}>
                <label className="form-label label">Seleccione la Causa*</label>
            </Col>
            <Col sm={2} xs={12}>
                <button
                    type="button"
                    className="btn botonPositivo"
                    onClick={SelectCausa}
                >
                    Seleccionar
                </button>
            </Col>
            <Col sm={2} xs={12}>
            {selectedMacroriesgos[0] ? (
                <button
                type="button"
                className="btn botonNegativo"
                onClick={DesasociarMacroriesgo}
                >
                Deseleccionar
                </button>
            ) : (
                <></>
            )}
            </Col>
        </Row>

        <Row className="mb-4">
            <Paper className={classes.root}>
                <TableContainer component={Paper} className={classes.container}>
                <Table
                    className={"text"}
                    stickyHeader
                    aria-label="sticky table"
                >
                {/* Inicio de encabezado */}
                <TableHead className="titulo">
                    <TableRow>
                        <StyledTableCell padding="checkbox"></StyledTableCell>
                        <StyledTableCell align="left">ID Causa</StyledTableCell>
                        <StyledTableCell align="left">Nombre</StyledTableCell>
                        <StyledTableCell align="left">Compañía</StyledTableCell>
                        <StyledTableCell align="left">Subcategoría Causa</StyledTableCell>
                        <StyledTableCell align="left">Factor</StyledTableCell>
                        <StyledTableCell align="left">Aristas</StyledTableCell>
                        <StyledTableCell align="left">Efectividad Grupo de Controles</StyledTableCell>
                        <StyledTableCell align="left">Calificación Impacto</StyledTableCell>
                        <StyledTableCell align="left">Calificación Probabilidad</StyledTableCell>
                    </TableRow>
                </TableHead>
                {/* Fin de encabezado */}
                {/* Inicio de cuerpo de la tabla */}
                <TableBody>
                    {dataMacroriesgos.map((row, index) => {
                    const isItemSelected = isSelectedMacroriesgos(
                        row.idcausa
                    );
                    return (
                        <StyledTableRow
                        key={row.idcausa}
                        hover={true}
                        onClick={(event) =>
                            handleClickMacroriesgos(event, row.idcausa)
                        }
                        selectedMacroriesgos={isItemSelected}
                        role="checkbox"
                        tabIndex={-1}
                        >
                        <StyledTableCell component="th" scope="row">
                            <Checkbox checked={isItemSelected} />
                        </StyledTableCell>

                        <StyledTableCell component="th" scope="row">
                            {row.idcausa}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.nombre ? row.nombre : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.compania ? row.compania : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.categoria_causa ? row.categoria_causa : null}
                        </StyledTableCell>
            
                        <StyledTableCell align="left">
                          {row.factor ? row.factor : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.arista_causa ? row.arista_causa : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.efectividad_grupo_controles ? row.efectividad_grupo_controles : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.calificacion_impacto ? row.calificacion_impacto : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.calificacion_probabilidad ? row.calificacion_probabilidad : null}
                        </StyledTableCell>
                        </StyledTableRow>
                    );
                    })}
                </TableBody>
                {/* Fin de cuerpo de la tabla */}
                </Table>
            </TableContainer>
            </Paper>
        </Row>
        {/* Una vez seleccionado los datos, aparecerá lo siguiente */}
        {dataMacroriesgos[0] ? (
        <>
        <Row className="mb-3">
            <Col sm={6} xs={12}>
                <label className="form-label label">Seleccione la Fecha Corte*</label>
            </Col>
            <Col sm={3} xs={12}>
                <Controller
                    control={control}
                    name="corte"
                    rules={{
                    required: "Te faltó completar este campo",
                    }}
                    render={({ field }) => (
                    <Select
                        ref={selectInputRef}
                        components={animatedComponents}
                        options={listaSemestres}
                        onChange={(e) => {
                        field.onChange(e);
                        setFechaCorte(e.label);
                        ApiFechaCorte(e.label, dataMacroriesgos);
                        //CalcularPromedioProbabilidad(dataExpertos);
                        
                        //setFlagFechaCorte(true);
                        }}
                        value={field.value}
                        placeholder="Seleccione la Fecha de Corte"
                    />
                    )}
                />
                <p className="text-center">{errors.corte?.message}</p>
            </Col>
        </Row>
        {impactoData ? (
        <>
        <Row className="mb-3">
            <Col sm={12} xs={12}>
                <label className="subtitulo text-left">Impacto</label>
            </Col>
        </Row>
            <Container>
              <Row className="mb-3">
                <Col sm={3} xs={12}>
                  <label className="form-label label">
                    Método de valoración
                  </label>
                </Col>

                <Col sm={4} xs={12}>
                  <Select
                    components={animatedComponents}
                    placeholder="Método de valoración"
                    options={[{ value: 0, label: "Delphi – Impacto LAFT" }]}
                    align="center"
                    value={metodoImpacto}
                    onChange={(e) => {
                      setMetodoImpacto(e);
                    }}
                  />
                </Col>
              </Row>
            </Container>
        </>
        ) : (
        <></>
        )}
            {metodoImpacto !== null && metodoImpacto.value === 0 ? (
            <>
                <Row className="mb-4">
                <Col sm={3} xs={12}>
                    <label className="form-label label">Consecuencias</label>
                </Col>
                <Col sm={9} xs={12}>
                    <Paper className={classes.root}>
                    <TableContainer
                        component={Paper}
                        className={classes.container}
                    >
                        <Table>
                        <TableHead className="titulo">
                            <TableRow>
                            <StyledTableCell align="left">
                                Riesgo reputacional
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                Riesgo legal
                            </StyledTableCell>

                            <StyledTableCell align="left">
                                Riesgo operacional
                            </StyledTableCell>

                            <StyledTableCell align="left">
                                Riesgo de contagio
                            </StyledTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {dataConsecuencias.map((row, index) => {
                            return (
                                <StyledTableRow role="checkbox" tabIndex={-1}>
                                <StyledTableCell align="left">
                                    {row.riesgo_reputacional
                                    ? row.riesgo_reputacional
                                    : null}
                                </StyledTableCell>

                                <StyledTableCell align="left">
                                    {row.riesgo_legal
                                    ? row.riesgo_legal
                                    : null}
                                </StyledTableCell>

                                <StyledTableCell align="left">
                                    {row.riesgo_operacional
                                    ? row.riesgo_operacional
                                    : null}
                                </StyledTableCell>

                                <StyledTableCell align="left">
                                    {row.riesgo_contagio
                                    ? row.riesgo_contagio
                                    : null}
                                </StyledTableCell>
                                </StyledTableRow>
                            );
                            })}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    </Paper>
                </Col>
                </Row>

                <Row className="mb-3">
                <Col sm={3} xs={12}>
                    <label className="form-label label">
                    Calificación impacto
                    </label>
                </Col>
                <Col sm={3} xs={12}>
                    <input
                    {...register("calificacionImpacto")}
                    type="text"
                    disabled
                    className="form-control text-center texto"
                    />
                </Col>
                </Row>
            </>
            ): (
            <></>
            )}
        <hr />
        <Row className="mb-3">
            <Col sm={12} xs={12}></Col>
        </Row>
        <Row className="mb-3">
            <Col sm={12} xs={12}></Col>
        </Row>  
        <Row className="mb-3">
            <Col sm={12} xs={12}></Col>
        </Row>  
        {probabilidadData ? (
        <>
        <Row className="mb-3">
            <Col sm={12} xs={12}>
                <label className="subtitulo text-left">Probabilidad</label>
            </Col>
        </Row>
        <Container>
            <Row className="mb-3">
                <Col sm={3} xs={12}>
                    <label className="form-label label">
                    Método de valoración
                    </label>
                </Col>
                <Col sm={4} xs={12}>
                    <Select
                    components={animatedComponents}
                    placeholder="Método de valoración"
                    options={[
                        {
                        value: "Indicadores de probabilidad",
                        label: "Indicadores de probabilidad",
                        },
                        { value: "Expertos", label: "Expertos" },
                    ]}
                    value={metodoProbabilidad}
                    onChange={(e) => {
                        setMetodoProbabilidad(e);
                        if (e.label === "Expertos") {
                        //setFlagExpertos(true);
                        //llenarSemestres();
                        } else {
                        //setFlagExpertos(false);
                        }
                    }}
                    />
                </Col>
            </Row>
        </Container>
        {metodoProbabilidad !== null && metodoProbabilidad.value === "Indicadores de probabilidad" ? (
            <>
                <Row className="mb-4">
                    <Col sm={3} xs={12}>
                        <label className="form-label label">Indicadores</label>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm={8} xs={12}></Col>
                    <Col sm={2} xs={12}>
                          {selectedIndicadores[0] ? (
                            <button
                              type="button"
                              className="btn botonNegativo"
                              onClick={() => {
                                history.push({
                                  pathname: "/EditarIndicador",
                                  state: {
                                    idindicador: selectedIndicadores[0],
                                  },
                                });
                              }}
                            >
                              Editar
                            </button>
                          ) : (
                            <></>
                          )}
                        </Col>
                        <Col sm={2} xs={12}>
                          <Link to="/CrearIndicador" target="_blank">
                            <button type="button" className="btn botonPositivo">
                              Crear
                            </button>
                          </Link>
                        </Col>
                </Row>

                      <Row className="mb-4">
                        <Col sm={12} xs={12}>
                            <Paper className={classes.root}>
                            <TableContainer
                                component={Paper}
                                className={classes.container}
                            >
                                <Table>
                                <TableHead className="titulo">
                                    <TableRow>
                                    <StyledTableCell padding="checkbox"></StyledTableCell>

                                    <StyledTableCell align="left">
                                        ID Indicador
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                        Segmento a priori
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                        Clúster
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                        Fórmula
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                        Resultado Fórmula
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                        Valor Probabilidad
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                        Valor Riesgo Total-Causa-Segmento
                                        Inherente
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                        Valor Riesgo Total-Causa-Segmento Residual
                                    </StyledTableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {dataIndicadores.map((row, index) => {
                                    const isItemSelected =
                                        isSelectedIndicadores(row.idindicador);
                                    return (
                                        <StyledTableRow
                                        key={row.idindicador}
                                        hover
                                        onClick={(event) =>
                                            handleClickIndicadores(
                                            event,
                                            row.idindicador
                                            )
                                        }
                                        selected={isItemSelected}
                                        role="checkbox"
                                        tabIndex={-1}
                                        >
                                        <StyledTableCell
                                            component="th"
                                            scope="row"
                                        >
                                            <Checkbox checked={isItemSelected} />
                                        </StyledTableCell>

                                        <StyledTableCell
                                            component="th"
                                            scope="row"
                                        >
                                            {row.idindicador
                                            ? row.idindicador
                                            : null}
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                            {row.segmento_a_priori ? row.segmento_a_priori : null}
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                            {row.cluster ? row.cluster : null}
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                            {row.formula ? row.formula : null}
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                            {row.resultado_formula
                                            ? row.resultado_formula
                                            : null}
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                            {row.valor_probabilidad
                                            ? row.valor_probabilidad
                                            : null}
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                            {row.riesgo_causa_inherente
                                            ? row.riesgo_causa_inherente
                                            : null}
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                            {row.riesgo_causa_residual
                                            ? row.riesgo_causa_residual
                                            : null}
                                        </StyledTableCell>
                                        </StyledTableRow>
                                    );
                                    })}
                                </TableBody>
                                </Table>
                            </TableContainer>
                            </Paper>
                    </Col>
                </Row>
                <Row className="mb-3">
                        <Col sm={3} xs={12}>
                          <label className="form-label label">
                            Calificación probabilidad
                          </label>
                        </Col>
                        <Col sm={3} xs={12}>
                          <input
                            {...register("calificacionProbabilidadIndicadores")}
                            type="text"
                            disabled
                            className="form-control text-center texto"
                          />
                        </Col>
                      </Row>
        <hr />

        <Row className="mb-3">
              <Col sm={12} xs={12}>
                <label className="subtitulo text-left">Resultado</label>
              </Col>
            </Row>

            <Container>
              <Row className="mb-3">
                <Col sm={3} xs={12}>
                  <label className="form-label label">
                    Riesgo-Causa Inherente
                  </label>
                </Col>
                <Col sm={3} xs={12}>
                  <input
                    {...register("riesgoCausaInherenteIndicadores")}
                    type="text"
                    disabled
                    className="form-control text-center texto"
                  />
                </Col>

                <Col sm={3} xs={12}>
                  <label className="form-label label">
                    Riesgo-Causa Residual
                  </label>
                </Col>
                <Col sm={3} xs={12}>
                  <input
                    {...register("riesgoCausaResidualIndicadores")}
                    type="text"
                    disabled
                    className="form-control text-center texto"
                  />
                </Col>
              </Row>
            </Container>
            </>
                ) : (
            <></>
            )}
    {metodoProbabilidad !== null && metodoProbabilidad.value === "Expertos" ? (
        <>
        <Row className="mb-3">
            <Col sm={12} xs={12}>
                <label className="subtitulo text-left">Expertos</label>
            </Col>
        </Row>
        <Row className="mb-3">
                        <Col sm={12} xs={12}>
                          <Paper className={classes.root}>
                            <TableContainer
                              component={Paper}
                              className={classes.container}
                            >
                              <Table>
                                {/* Inicio de encabezado */}
                                <TableHead className="titulo">
                                  <TableRow>
                                    <StyledTableCell padding="checkbox"></StyledTableCell>

                                    <StyledTableCell align="left">
                                      ID valoración probabilidad
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      Experto
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      ID Causa
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      Fecha Corte
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      Estado
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      Probabilidad
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {dataExpertos.map((row, index) => {
                                    const isItemSelected = isSelectedExpertos(
                                      row.idvaloracion_probabilidad
                                    );
                                    return (
                                      <StyledTableRow
                                        key={row.idvaloracion_probabilidad}
                                        hover
                                        onClick={(event) =>
                                          handleClickExpertos(
                                            event,
                                            row.idvaloracion_probabilidad
                                          )
                                        }
                                        selected={isItemSelected}
                                        role="checkbox"
                                        tabIndex={-1}
                                      >
                                        <StyledTableCell
                                          component="th"
                                          scope="row"
                                        >
                                          <Checkbox checked={isItemSelected} />
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                          {row.idvaloracion_probabilidad
                                            ? row.idvaloracion_probabilidad
                                            : null}
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                          {row.nombre_usuario
                                            ? row.nombre_usuario
                                            : null}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {row.idcausa
                                            ? row.idcausa
                                            : null}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {row.fecha_corte ? row.fecha_corte : null}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                          {row.estado ? row.estado : null}
                                        </StyledTableCell>

                                        <StyledTableCell align="left">
                                          {row.probabilidad ? row.probabilidad : null}
                                        </StyledTableCell>
                                      </StyledTableRow>
                                    );
                                  })}
                                </TableBody>
                                {/* Fin de cuerpo de la tabla */}
                              </Table>
                            </TableContainer>
                          </Paper>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col sm={3} xs={12}>
                          <label className="form-label label">
                            Calificación probabilidad
                          </label>
                        </Col>
                        <Col sm={3} xs={12}>
                          <input
                            {...register("calificacionProbabilidadExpertos")}
                            type="text"
                            disabled
                            className="form-control text-center texto"
                          />
                        </Col>
                      </Row>
        <hr />

        <Row className="mb-3">
              <Col sm={12} xs={12}>
                <label className="subtitulo text-left">Resultado</label>
              </Col>
            </Row>

            <Container>
              <Row className="mb-3">
                <Col sm={3} xs={12}>
                  <label className="form-label label">
                    Riesgo-Causa Inherente
                  </label>
                </Col>
                <Col sm={3} xs={12}>
                  <input
                    {...register("riesgoCausaInherenteExpertos")}
                    type="text"
                    disabled
                    className="form-control text-center texto"
                  />
                </Col>

                <Col sm={3} xs={12}>
                  <label className="form-label label">
                    Riesgo-Causa Residual
                  </label>
                </Col>
                <Col sm={3} xs={12}>
                  <input
                    {...register("riesgoCausaResidualExpertos")}
                    type="text"
                    disabled
                    className="form-control text-center texto"
                  />
                </Col>
              </Row>
            </Container>
        </>
        ) : (
        <></>
        )}

        </>
        ) : (
        <></>
        )}
        </>
        ) : (
        <></>
        )}
        <Row className="mb-3">
            <Col sm={12} xs={12}></Col>
        </Row>
        <Row className="mb-3">
            <Col sm={12} xs={12}></Col>
        </Row>
        <Row className="mb-3">
            <Col sm={12} xs={12}></Col>
        </Row>
        <Row className="mb-3">
            <Col sm={12} xs={12}></Col>
        </Row>
        <Row className="mb-3">
            <Col sm={12} xs={12}></Col>
        </Row>
        <Row className="mb-3">
            <Col sm={12} xs={12}></Col>
        </Row>
        </FormProvider>
        </Container>
    </>
  );
}
