import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useLocalStorage } from '../hooks/useLocalStorage';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Loader from 'react-loader-spinner';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';

import AADService from "../auth/authFunctions";

const serviceAAD = new AADService();


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#2c2a29',
    color: theme.palette.common.white,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: '#f4f4f4',
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '60vh',
    minHeight: '60vh',
  },
});

export default function RiesgosParciales(props) {
  const [selected, setSelect] = useState([]);
  const [_, setValue] = useLocalStorage('Selected', '');
  const [loadingData, setLoadingData] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [ButtonEdit, SetButtonEdit] = useState(false);
  const [grid, info_grid] = useState([]);
  const [dataBusqueda, setDataBusqueda] = useState('');
  const router = useHistory();
  const classes = useStyles();



  useEffect(() => {
    (async () => {
      try {
        setLoadingData(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/asistentero/mostrar_view_grid_por_usuario/jonflore@bancolombia.com.co/`,
          {
            Authorization: "Bearer " + serviceAAD.getToken()
          }
          );
        if (response.ok) {
          const data = await response.json();
          info_grid(data);
        }
      } catch (error) {
        throw new Error(`An error has ocurred in GET ${error}`);
      } finally {
        setLoadingData(false);
      }
    })();
  }, []);

  const handleChange = (e) => {
    setDataBusqueda(e.target.value);
    console.log(dataBusqueda);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], name);
      SetButtonEdit(true);
    } else {
      SetButtonEdit(false);
    }
    setSelect(newSelected);
    setValue(newSelected.join())
  };


  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <>
      <Row className="mb-3 mt-3">
        <Col md={12}>
          <h1 className="titulo">Riesgos Parciales</h1>
        </Col>
      </Row>
      <Row
        style={{ marginTop: '1%', marginBottom: '0.5%' }}
        className="mb-3 mt-3"
      >
        <Col sm={4} xs={12}>
          <Form>
            <Form.Control
              onChange={(e) => handleChange(e)}
              type="text"
              placeholder="Buscar"
            />
          </Form>
        </Col>
        <Col style={{ paddingTop: '0.3%' }} sm={2} xs={6} />
        <Col
          style={{ paddingTop: '0.3%' }}
          className="d-flex justify-content-end"
          sm={3}
          xs={6}
        >
          <Button
            className="botonNegativo"
            style={{
              display:
                ButtonEdit && (props.permisos.editar || props.permisos.ver)
                  ? 'inline'
                  : 'none',
            }}
            onClick={() => router.push('/EditarIdentificador')}
          >
            Consultar Analisis
          </Button>
        </Col>
        <Col
          style={{ paddingTop: '0.3%' }}
          className="d-flex justify-content-end"
          sm={3}
          xs={6}
        >
          <Button
            onClick={() => {
              router.push('/Identificadores');
              localStorage.removeItem('Selected');
            }}
            type="button"
            className="botonNegativo3 btn btn-primary"
          >
            Nuevo Analisis
          </Button>
        </Col>
      </Row>
      {loadingData ? (
        <Row className="mb-3 mt-5">
          <Col>
            <Loader
              type="Oval"
              color="#FFBF00"
              style={{ textAlign: 'center', position: 'static' }}
            />
          </Col>
        </Row>
      ) : (
        <Paper className={classes.root}>
          <TableContainer component={Paper} className={classes.container}>
            <Table className={'text'} stickyHeader aria-label="sticky table">
              <TableHead className="titulo">
                <TableRow>
                  <StyledTableCell padding="checkbox" />
                  <StyledTableCell>ID Elemento</StyledTableCell>
                  <StyledTableCell align="left">
                    Nombre Elemento
                  </StyledTableCell>
                  <StyledTableCell align="left">Usuario</StyledTableCell>
                  <StyledTableCell align="left">
                    Fecha Modificacion
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {grid
                  .filter((item) => {
                    // Filtrar los elementos según el término de búsqueda
                    return (
                      item.nombre_elemento
                        .toLowerCase()
                        .includes(dataBusqueda.toLowerCase()) ||
                      item.nombre_usuario
                        .toLowerCase()
                        .includes(dataBusqueda.toLowerCase()) ||
                      item.id_elemento
                        .toString()
                        .includes(dataBusqueda.toLowerCase())
                      // Agrega otras propiedades que quieras incluir en la búsqueda
                    );
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((gridItem, index) => {
                    const selectGrid = isSelected(gridItem.id_elemento);

                    return (
                      <StyledTableRow
                        key={gridItem.id_elemento}
                        hover
                        onClick={(event) =>
                          handleClick(event, gridItem.id_elemento)
                        }
                        selected={selectGrid}
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <StyledTableCell component="th" scope="row">
                          <Checkbox checked={selectGrid} />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {gridItem.id_elemento || null}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {gridItem.nombre_elemento || null}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {gridItem.nombre_usuario || null}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {gridItem.fecha_modificacion || null}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[20, 30, 100]}
            component="div"
            count={grid.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </>
  );
}
