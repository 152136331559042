import axios from "axios";
import AADService from "../../../auth/authFunctions";
import React, { useState, useEffect } from "react";
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
} from "react-hook-form";
import { Row, Col, Button, Form, Container, Modal } from "react-bootstrap";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";

import Select from "react-select";
import makeAnimated from "react-select/animated";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const animatedComponents = makeAnimated();

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",
    color: theme.palette.common.white,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
    heigth: "10px",
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    /* maxHeight: "60vh", */
    minHeight: "20vh",
  },

  MuiTableRow: {
    root: {
      //This can be referred from Material UI API documentation.
      heigth: "10px",
    },
  },
});

export default function ModalBuscador(props) {
  const serviceAAD = new AADService();
  const classes = useStyles();
  const [estadoPost, setEstadoPost] = useState({
    alerta: { id: 0, data: null },
  });

  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [selected, setSelected] = React.useState([]);

  const [buscando, setBuscando] = React.useState(null);
  const [dataBusqueda, setDataBusqueda] = React.useState([]);

  const [ButtonEdit, SetButtonEdit] = React.useState(false);

  const { setValue, getValues } = useFormContext();

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], name);
      SetButtonEdit(true);
    } else {
      SetButtonEdit(false);
    }
    setSelected(newSelected);
  };

  async function buscar(e) {
    e.persist();

    setBuscando(e.target.value);

    var search = data.filter((item) => {
      if (
        String(item.idcausa)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.compania)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.nombre)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.descripcion)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        String(item.factor).toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        return item;
      }
    });
    //await setBuscando(e.target.value);
    //await setDataBusqueda(search);
    await setDatosBusqueda(e.target.value, search);
  }

  const setDatosBusqueda = async (buscando, busqueda) => {
    setBuscando(buscando);
    setDataBusqueda(busqueda);
  };

  const Asociar = () => {
    let idData = selected[0];

    axios
      .get(process.env.REACT_APP_API_URL + "/causas/" + idData, {
        headers: {
          Authorization: "Bearer " + serviceAAD.getToken(),
        },
      })
      .then((response) => {
        let listSegmentos = response.data.segmentos;
        let info_causa = {nombre: response.data.nombre, idcausa: idData}
        let listSegmentosMap = listSegmentos.map(
          ({ idsegmentos: value, nombre: label, cluster, descripcion }) => ({
            value,
            label,
            cluster,
            descripcion,
          })
        );
        setValue("idUbicacion", info_causa.nombre.toString());
        props.setElementoId(info_causa);
        props.setListaSegmentos(listSegmentosMap);
      });

    setSelected([]);
    props.onHide();
  };

  useEffect(() => {
    //---------------------- Listas  ---------------------
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };

    let urlArray = [
      { unidad: "Causa", url: process.env.REACT_APP_API_URL + "/causas/" },
      //{ unidad: "Control", url: process.env.REACT_APP_API_URL + "/controles/" },
    ];

    let unidadRiesgo = getValues("ubicacion");
    let url = null;

    console.log("unidadRiesgo", unidadRiesgo);

    if (unidadRiesgo !== undefined && unidadRiesgo !== null) {
      url = urlArray.filter((item) => item.unidad === unidadRiesgo.label)[0]
        .url;
    }

    // alert("Debe seleccionar una unidad de riesgo");
    // props.onHide();

    let APIS = [fetch(url, config)];

    if (url !== null) {
      Promise.all(APIS)
        .then(async ([data]) => {
          const list = await data.json();

          setData(list);
          setDataBusqueda(list);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props.show]);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Buscador</Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <Row className="mb-3 mt-3">
            <Col sm={4} xs={12}>
              <Form>
                <Form.Control
                  value={buscando}
                  onChange={(e) => buscar(e)}
                  type="text"
                  placeholder="Buscar"
                />
              </Form>
            </Col>
          </Row>
          <Row className="mb-3 mt-3">
            <Paper className={classes.root}>
              <TableContainer component={Paper} className={classes.container}>
                <Table
                  className={"text"}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead className="titulo">
                    <TableRow>
                      <StyledTableCell padding="checkbox"></StyledTableCell>
                      <StyledTableCell>ID</StyledTableCell>
                      <StyledTableCell align="left">Nombre</StyledTableCell>
                      <StyledTableCell align="left">Compañia</StyledTableCell>
                      <StyledTableCell align="left">Factor</StyledTableCell>
                      <StyledTableCell align="left">
                        Descripción
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {dataBusqueda
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.idcausa);
                        return (
                          <StyledTableRow
                            key={row.idcausa}
                            hover
                            onClick={(event) => handleClick(event, row.idcausa)}
                            selected={isItemSelected}
                            role="checkbox"
                            tabIndex={-1}
                          >
                            <StyledTableCell component="th" scope="row">
                              <Checkbox checked={isItemSelected} />
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {row.idcausa !== null ? row.idcausa : null}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.nombre !== null ? row.nombre : null}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.compania !== null ? row.compania : null}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.factor !== null ? row.factor : null}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.descripcion !== null
                                ? row.descripcion
                                : null}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[20, 30, 100]}
                component="div"
                count={dataBusqueda.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button className="botonPositivo" onClick={Asociar}>
          Asociar
        </Button>
        <Button className="botonNegativo" onClick={props.onHide}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
