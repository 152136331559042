import React, { useEffect, useState, useRef} from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Row,
  Col,
  Form,
  Alert,
  Button,
  Container,
  Modal,
} from "react-bootstrap";

import axios from "axios";

import AADService from "../../auth/authFunctions";

import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TextField from "@material-ui/core/TextField";
import TableContainer from "@material-ui/core/TableContainer";

import Select from "react-select";
import makeAnimated from "react-select/animated";

import ModalExpertos from "./Modales/ModalExpertos";
import ModalAsociarMacroriesgos from "./Modales/ModalAsociarMacroriesgos";
import ModalExpertosProbabilidad from "../ValoracionImpacto/Modales/ModalExpertosProbabilidad";

const animatedComponents = makeAnimated();

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",
    color: theme.palette.common.white,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
    heigth: "10px",
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    /* maxHeight: "60vh", */
    minHeight: "20vh",
  },

  MuiTableRow: {
    root: {
      //This can be referred from Material UI API documentation.
      heigth: "10px",
    },
  },
});

function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;

    case 2:
      return <Alert variant="success">Guardó exitosamente</Alert>;

    case 3:
      return <Alert variant="danger"></Alert>;

    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;

    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;

    case 7:
      return (
        <Alert variant="warning">
          Corrige los siguientes errores:
          <br></br>• Debe completar los campos obligatorios
        </Alert>
      );

    default:
      return <p></p>;
  }
}

export default function ValoracionProbabilidad() {
  const classes = useStyles();
  const selectInputRef = useRef();
  const serviceAAD = new AADService();
  const location = useLocation();
  const history = useHistory();
  const [id, setID] = useState(null);

  const [estadoPost, setEstadoPost] = useState({
    alerta: { id: 0, data: null },
  });

  const [listaSemestres, setListaSemestres] = useState([]);

  //--------------------------------------------------------
  const [showAsociarMacroriesgo, setShowAsociarMacroriesgo] = useState(false);
  const [selectedMacroriesgos, setSelectedMacroriesgos] = useState([]);
  const [dataMacroriesgos, setDataMacroriesgos] = useState([]);
  const [fechaCorte, setFechaCorte] = useState(null);
  const [dataExpertos, setDataExpertos] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedExpertos, setSelectedExpertos] = useState([]);

  const isSelectedMacroriesgos = (name) =>
  selectedMacroriesgos.indexOf(name) !== -1;

  const isSelectedExpertos = (name) => selectedExpertos.indexOf(name) !== -1;

  const handleClickMacroriesgos = (event, name) => {
    const selectedIndex = selectedMacroriesgos.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], name);
      //SetButtonEdit(true);
    } else {
      //SetButtonEdit(false);
    }

    setSelectedMacroriesgos(newSelected);
  };

  const handleClickExpertos = (event, name) => {
    const selectedIndex = selectedExpertos.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], name);
      //SetButtonEdit(true);
    } else {
      //SetButtonEdit(false);
    }

    setSelectedExpertos(newSelected);
  };


  const defaultValues = {
    id: null,
    companias: null,
  };

  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = methods;

  const llenarSemestres = () => {
    let fechaActual = new Date();
    let anioActual = fechaActual.getFullYear();
    let mesActual = fechaActual.getMonth();
    let semestreActual = mesActual >= 6 ? 2 : 1;
  
    let tempListaSemestres = [];
  
    for (let anio = 2022; anio <= anioActual; anio++) {
      for (let semestre = 1; semestre <= 2; semestre++) {
        if (anio === anioActual && semestre > semestreActual) {
          break;
        }
        tempListaSemestres.push({
          value: String(anio) + "-" + semestre,
          label: String(anio) + "-" + semestre,
        });
      }
    }
  
    setListaSemestres(tempListaSemestres);
    return tempListaSemestres;
  };

  const ApiExpertos = (fecha, idcausa) => {
    let url = process.env.REACT_APP_API_URL + "/valoracioncausaprobabilidad/";

    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };
    try {
      axios.get(url + fecha + "/" + idcausa + "/" ,config).then((response) => {
        console.log('response.data expertos', response.data)
        setDataExpertos(response.data.expertosData);
        //sessionStorage.setItem("corte", fecha);

      });
    } catch (error) {
      console.error(error);
    }
  };


  const ApiFechaCorte = (fecha, data) => {
    console.log("Estoy en la función ApiFechaCorte")
    console.log("Fecha: " + fecha)
    console.log("Data: " + data)
    let idcausa = data[0].idcausa;
    console.log("idcausa: " + idcausa);
    setID(idcausa)

    let url = process.env.REACT_APP_API_URL + "/valoracioncausaprobabilidad/";

    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };
    try {
      axios.get(url + fecha + "/" + idcausa + "/" ,config).then((response) => {
        console.log('response.data expertos', response.data)
        setDataExpertos(response.data.expertosData);
        //sessionStorage.setItem("corte", fecha);

      });
    } catch (error) {
      console.error(error);
    }


  };


  const EnviarValoracion = () => {
    console.log('Estoy en el envio de la valoracion')
    let ids = [];
    let ids_enviados = [];
    let ids_finalizados = [];
    console.log('dataExpertos', dataExpertos)
    dataExpertos.forEach((item) => {
      if (item.estado === "No Enviado") {
        ids.push(item.idvaloracion_probabilidad);
      }
      if (item.estado === "Enviado") {
        ids_enviados.push(item.idvaloracion_probabilidad);
      }
      if (item.estado === "Finalizado") {
        ids_finalizados.push(item.idvaloracion_probabilidad);
      }
    });
    console.log('dataExpertos2', dataExpertos)
    console.log('ids', ids)
    console.log('ids_enviados', ids_enviados)
    console.log('ids_finalizados', ids_finalizados)

    let url = process.env.REACT_APP_API_URL + "/valoracion_expertos/";

    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    };
    console.log('Antes del try')
    try {
      console.log('Estoy en el try')
      axios
        .put(url + "/" + fechaCorte + "/",  { ids_expertos: ids, ids_enviados: ids_enviados, ids_finalizados: ids_finalizados, idcausa: id }, config)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            dataExpertos.map((item) => {
              item.estado = "Enviado";
            });

            let fechaCorte = getValues("corte");
            ApiExpertos(fechaCorte.label, id);
            alert("Valoraciones enviadas o desasociadas correctamente");
          } else if (response.status >= 500) {
          } else if (response.status >= 400 && response.status < 500) {
          }
        });
    } catch (error) {
      console.log('Estoy en el catch')
      console.error(error);
    }
  };

  const SelectCausa = () => {
    console.log("Estoy en la función SelectCausa")
    setShowAsociarMacroriesgo(true);
    setDataExpertos([]);
    selectInputRef.current.select.clearValue();

};

const DesasociarMacroriesgo = () => {
  if (selectedMacroriesgos[0]) {
    const selectedData = dataMacroriesgos.filter(
      (macroriesgo) => macroriesgo.idcausa !== selectedMacroriesgos[0]
    );
    setDataMacroriesgos(selectedData);
    setSelectedMacroriesgos([]);
    setDataExpertos([]);
    selectInputRef.current.select.clearValue();

  } else {
  }
};

const DesasociarExperto = () => {
  console.log('Estoy en el desasociar Experto')
  if (selectedExpertos[0]) {
    const newDataArray = dataExpertos.filter(
      (item) => item.idvaloracion_probabilidad !== selectedExpertos[0]
    );
    console.log('Estoy en el desasociar', newDataArray)
    //ApiFechaCorte(fechaCorte);

    setDataExpertos(newDataArray);

    setSelectedExpertos([]);
  }
};


  useEffect(() => {
    const listaDeSemestres = llenarSemestres();
  }, []);

  return (
    <>
      <AlertDismissibleExample alerta={estadoPost} />
      <Container fluid>
        <FormProvider {...methods}>
          {/* <----------------------------------------Modales----------------------------------------> */}
          <ModalAsociarMacroriesgos
              show={showAsociarMacroriesgo}
              onHide={() => setShowAsociarMacroriesgo(false)}
              dataMacroriesgos={dataMacroriesgos}
              setDataMacroriesgos={setDataMacroriesgos}
            />

          <ModalExpertosProbabilidad
            show={show}
            onHide={() => {
              setShow(false);
              //setIsEditing(false);
              setSelectedExpertos([]);
            }}
            //isEditing={isEditing}
            //data={dataExpertos}
            //setData={setDataExpertos}
            //selected={selectedExpertos}
            fechaCorte={fechaCorte}
            idCausa={id}
            ApiExpertos={ApiExpertos}
          />

          {/* <----------------------------------------Titulo----------------------------------------> */}
          <Row className="mb-3 mt-3">
            <Col sm={12} xs={12}>
              <h1 className="titulo">Valoración Probabilidad ADMINISTADOR</h1>
            </Col>
          </Row>
          <hr />
          {/* <----------------------------------------Formulario----------------------------------------> */}
          <Row className="mb-3">
            <Col sm={6} xs={12}>
                <label className="form-label label">Seleccione la Causa*</label>
            </Col>
            <Col sm={2} xs={12}>
                <button
                    type="button"
                    className="btn botonPositivo"
                    onClick={SelectCausa}
                >
                    Seleccionar
                </button>
            </Col>
            <Col sm={2} xs={12}>
            {selectedMacroriesgos[0] ? (
                <button
                type="button"
                className="btn botonNegativo"
                onClick={DesasociarMacroriesgo}
                >
                Deseleccionar
                </button>
            ) : (
                <></>
            )}
            </Col>
        </Row>
        <Row className="mb-4">
            <Paper className={classes.root}>
                <TableContainer component={Paper} className={classes.container}>
                <Table
                    className={"text"}
                    stickyHeader
                    aria-label="sticky table"
                >
                {/* Inicio de encabezado */}
                <TableHead className="titulo">
                    <TableRow>
                        <StyledTableCell padding="checkbox"></StyledTableCell>
                        <StyledTableCell align="left">ID Causa</StyledTableCell>
                        <StyledTableCell align="left">Nombre</StyledTableCell>
                        <StyledTableCell align="left">Compañía</StyledTableCell>
                        <StyledTableCell align="left">Factor</StyledTableCell>
                        <StyledTableCell align="left">Aristas</StyledTableCell>
                        <StyledTableCell align="left">Estado</StyledTableCell>
                    </TableRow>
                </TableHead>
                {/* Fin de encabezado */}
                {/* Inicio de cuerpo de la tabla */}
                <TableBody>
                    {dataMacroriesgos.map((row, index) => {
                    const isItemSelected = isSelectedMacroriesgos(
                        row.idcausa
                    );
                    return (
                        <StyledTableRow
                        key={row.idcausa}
                        hover={true}
                        onClick={(event) =>
                            handleClickMacroriesgos(event, row.idcausa)
                        }
                        selectedMacroriesgos={isItemSelected}
                        role="checkbox"
                        tabIndex={-1}
                        >
                        <StyledTableCell component="th" scope="row">
                            <Checkbox checked={isItemSelected} />
                        </StyledTableCell>

                        <StyledTableCell component="th" scope="row">
                            {row.idcausa}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.nombre ? row.nombre : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.compania ? row.compania : null}
                        </StyledTableCell>
            
                        <StyledTableCell align="left">
                          {row.factor ? row.factor : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.arista_causa ? row.arista_causa : null}
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          {row.estado == 1 ? "Activo" : "Inactivo"}
                        </StyledTableCell>
                        </StyledTableRow>
                    );
                    })}
                </TableBody>
                {/* Fin de cuerpo de la tabla */}
                </Table>
            </TableContainer>
            </Paper>
        </Row>        
        {dataMacroriesgos[0] ? (
        <>
          <Row className="mb-3">
            <Col sm={6} xs={12}>
                <label className="form-label label">Seleccione la Fecha Corte*</label>
            </Col>
            <Col sm={3} xs={12}>
                <Controller
                    control={control}
                    name="corte"
                    rules={{
                    required: "Te faltó completar este campo",
                    }}
                    render={({ field }) => (
                    <Select
                        ref={selectInputRef}
                        components={animatedComponents}
                        options={listaSemestres}
                        onChange={(e) => {
                        field.onChange(e);
                        setFechaCorte(e.label);
                        ApiFechaCorte(e.label, dataMacroriesgos);
                        //CalcularPromedioProbabilidad(dataExpertos);
                        
                        //setFlagFechaCorte(true);
                        }}
                        value={field.value}
                        placeholder="Seleccione la Fecha de Corte"
                    />
                    )}
                />
                <p className="text-center">{errors.corte?.message}</p>
            </Col>
          </Row>
          <Row className="mb-3"><Col sm={12} xs={12}> {" "}</Col></Row>
          <Row className="mb-3"><Col sm={12} xs={12}> {" "}</Col></Row>
          <Row className="mb-3"><Col sm={12} xs={12}> {" "}</Col></Row>
          <Row className="mb-3"><Col sm={12} xs={12}> {" "}</Col></Row>
          <Row className="mb-3"><Col sm={12} xs={12}> {" "}</Col></Row>
          
          {dataExpertos ? ( 
          <>
            <Row className="mb-3">
              <Col sm={8} xs={12}></Col>
              <Col sm={2} xs={12}>
                <button
                  type="button"
                  className="btn botonPositivo"
                  onClick={() => setShow(true)}
                >
                  Asociar
                </button>
              </Col>
              <Col sm={2} xs={12}>
                {selectedExpertos[0] ? (
                <button
                  type="button"
                  className="btn botonNegativo"
                  onClick={DesasociarExperto}
                >
                  Desasociar
                </button>
                ) : null}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={12} xs={12}>
                <Paper className={classes.root}>
                  <TableContainer
                    component={Paper}
                    className={classes.container}
                  >
                    <Table>
                      {/* Inicio de encabezado */}
                      <TableHead className="titulo">
                        <TableRow>
                          <StyledTableCell padding="checkbox"></StyledTableCell>

                          <StyledTableCell align="left">
                            ID valoración probabilidad
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Experto
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            ID Causa
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Fecha Corte
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Estado
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Probabilidad
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {dataExpertos.map((row, index) => {
                          const isItemSelected = isSelectedExpertos(
                            row.idvaloracion_probabilidad
                          );
                          return (
                            <StyledTableRow
                              key={row.idvaloracion_probabilidad}
                              hover
                              onClick={(event) =>
                                handleClickExpertos(
                                  event,
                                  row.idvaloracion_probabilidad
                                )
                              }
                              selected={isItemSelected}
                              role="checkbox"
                              tabIndex={-1}
                            >
                              <StyledTableCell
                                component="th"
                                scope="row"
                              >
                                <Checkbox checked={isItemSelected} />
                              </StyledTableCell>

                              <StyledTableCell align="left">
                                {row.idvaloracion_probabilidad
                                  ? row.idvaloracion_probabilidad
                                  : null}
                              </StyledTableCell>

                              <StyledTableCell align="left">
                                {row.nombre_usuario
                                  ? row.nombre_usuario
                                  : null}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.idcausa
                                  ? row.idcausa
                                  : null}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.fecha_corte ? row.fecha_corte : null}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {row.estado ? row.estado : null}
                              </StyledTableCell>

                              <StyledTableCell align="left">
                                {row.probabilidad ? row.probabilidad : null}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                      {/* Fin de cuerpo de la tabla */}
                    </Table>
                  </TableContainer>
                </Paper>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={9} xs={12}>
                {" "}
              </Col>
              <Col sm={3} xs={12}>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={EnviarValoracion}
                  >
                    Enviar y Guardar
                  </button>
              </Col>
            </Row>
          </>
          ) : (
          <></>
          )} 
        </>
        ) : (
        <></>
        )}
        </FormProvider>
      </Container>
    </>
  );
}
