import React, { useEffect, useState, useContext, useCallback, useRef} from "react";
import { useForm, Controller, useWatch, FormProvider } from "react-hook-form";
import { Link, Routes, Route, useHistory, useLocation } from "react-router-dom";

import {
  Row,
  Col,
  Form,
  Alert,
  Button,
  Container,
  Modal,
} from "react-bootstrap";

import axios from "axios";

import AADService from "../../auth/authFunctions";

import { forwardRef } from "react";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import Loader from "react-loader-spinner";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TextField from "@material-ui/core/TextField";
import TableContainer from "@material-ui/core/TableContainer";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#2c2a29",
      color: theme.palette.common.white,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      backgroundColor: "#f4f4f4",
      heigth: "10px",
      zIndex: 0,
    },
  }))(TableRow);
  
  const useStyles = makeStyles({
    root: {
      width: "100%",
      zIndex: 0,
    },
    container: {
      /* maxHeight: "60vh", */
      minHeight: "20vh",
    },
  
    MuiTableRow: {
      root: {
        //This can be referred from Material UI API documentation.
        heigth: "10px",
      },
    },
  });

  function AlertDismissibleExample({ alerta }) {
  switch (alerta) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;

    case 2:
      return <Alert variant="success">Guardó exitosamente</Alert>;

    case 3:
      return <Alert variant="danger"></Alert>;

    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;

    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;

    case 7:
      return (
        <Alert variant="warning">
          Corrige los siguientes errores:
          <br></br>• Debe completar los campos obligatorios
        </Alert>
      );

    default:
      return <p></p>;
  }
}

export default function EditarValoracionLAFT() {    
    const serviceAAD = new AADService();
    const location = useLocation();

    const [id, setID] = useState(null);
    
    const defaultValues = {
    idcausa: null,

    };
    const methods = useForm({
        defaultValues,
        mode: "onChange",
    });

    const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
    } = methods;

    const llenarFormulario = async (id, fecha_corte) => {
        console.log("id a buscar: ", id);
        console.log("fecha_corte a buscar: ", fecha_corte);

        let url = process.env.REACT_APP_API_URL + "/causas_valoracion/";
        let config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + serviceAAD.getToken(),
            },
          };
          try {
            axios.get(url + fecha_corte + "/" + id + "/" ,config)
                .then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        let data = response.data[0];
                        console.log("data", data);
                        setValue("metodo_val_impacto", data.metodo_val_impacto);
                        setValue("metodo_val_probabilidad", data.metodo_val_probabilidad);
                        setValue("compania", data.compania);
                        setValue("calificacion_impacto", data.calificacion_impacto);
                        setValue("calificacion_probabilidad", data.calificacion_probabilidad);
                        setValue("valor_riesgo_causa_ihnerente", data.valor_riesgo_causa_ihnerente);
                        setValue("valor_riesgo_causa_residual", data.valor_riesgo_causa_residual);
                        setValue("nivel_riesgo_causa_ihnerente", data.nivel_riesgo_causa_ihnerente);
                        setValue("nivel_riesgo_causa_residual", data.nivel_riesgo_causa_residual);
                    }
            });
          } catch (error) {
            console.error(error);
          }
    }
    useEffect(() => {
        //---------------------------------------------------------Manejo de ids...
        console.log("Ubicación de donde provengo : ", location);
        if (typeof location.state != "undefined") {
            if (location.state.idCausa) {
              let id = location.state.idCausa;
              let fecha_corte = location.state.fecha_corte;
              setID(id);
              setValue("idcausa", id);
              setValue("fecha_corte", fecha_corte);
              llenarFormulario(id, fecha_corte);
            }
          } else {
            alert("Ups, ocurrió un error, trata de recargar la página");
          }
      }, []);

    return (
    <>
        <Row className="mb-3 mt-3">
            <Col sm={8} xs={12}>
                <h1 className="titulo">Valoración LAFT</h1>
            </Col>
            <Col sm={2} xs={12}>    
                <Link to="ValoracionLAFT">
                    <Button type="button" className="botonNegativo">
                        Cancelar
                    </Button>
                </Link>
            </Col>
        </Row>
        <hr />
        {/* <----------------------------------------Formulario----------------------------------------> */}
        <Row className="mb-4">
            <Col sm={2} xs={12}>
                <label className="forn-label label">ID Causa</label>
            </Col>
            <Col sm={2} xs={12}>
                <input
                    {...register("idcausa")}
                    disabled
                    type="text"
                    className="form-control text-center texto"
                    placeholder="ID Causa"
                />
            </Col>
            <Col sm={2} xs={12}>
                <label className="forn-label label">Fecha corte</label>
            </Col>
            <Col sm={2} xs={12}>
                <input
                    {...register("fecha_corte")}
                    disabled
                    type="text"
                    className="form-control text-center texto"
                    placeholder="Fecha Corte"
                />
            </Col>
            <Col sm={2} xs={12}>
                <label className="forn-label label">Compañía</label>
            </Col>
            <Col sm={2} xs={12}>
                <input
                    {...register("compania")}
                    disabled
                    type="text"
                    className="form-control text-center texto"
                    placeholder="Compañía"
                />
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={12} xs={12}>
                <label className="subtitulo text-left">Impacto</label>
            </Col>
        </Row>
        <Container>
            <Row className="mb-3">
                <Col sm={3} xs={12}>
                    <label className="form-label label">
                    Método de valoración
                    </label>
                </Col>

                <Col sm={4} xs={12}>
                    <input
                        {...register("metodo_val_impacto")}
                        disabled
                        type="text"
                        className="form-control text-center texto"
                        placeholder="Metodo val impacto"
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm={3} xs={12}>
                    <label className="form-label label">
                    Calificacion Impacto
                    </label>
                </Col>

                <Col sm={4} xs={12}>
                    <input
                        {...register("calificacion_impacto")}
                        disabled
                        type="text"
                        className="form-control text-center texto"
                        placeholder="Calificacion Impacto"
                    />
                </Col>
            </Row>
            </Container>
        <Row className="mb-3">
            <Col sm={12} xs={12}>
                <label className="subtitulo text-left">Probabilidad</label>
            </Col>
        </Row>
        <Container>
            <Row className="mb-3">
                <Col sm={3} xs={12}>
                    <label className="form-label label">
                    Método de valoración
                    </label>
                </Col>

                <Col sm={4} xs={12}>
                    <input
                        {...register("metodo_val_probabilidad")}
                        disabled
                        type="text"
                        className="form-control text-center texto"
                        placeholder="Metodo val probabilidad"
                    />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col sm={3} xs={12}>
                    <label className="form-label label">
                    Calificacion probabilidad
                    </label>
                </Col>

                <Col sm={4} xs={12}>
                    <input
                        {...register("calificacion_probabilidad")}
                        disabled
                        type="text"
                        className="form-control text-center texto"
                        placeholder="Calificacion Impacto"
                    />
                </Col>
            </Row>
            </Container>
        <hr />
            <Row className="mb-3">
            <Col sm={12} xs={12}>
                <label className="subtitulo text-left">Resultado</label>
            </Col>
        </Row>
        <Container>
            <Row className="mb-3">
                <Col sm={3} xs={12}>
                    <label className="form-label label">
                    Riesgo-Causa Inherente
                    </label>
                </Col>

                <Col sm={3} xs={12}>
                    <input
                        {...register("valor_riesgo_causa_ihnerente")}
                        disabled
                        type="text"
                        className="form-control text-center texto"
                        placeholder="Riesgo-Causa Inherente"
                    />
                </Col>
                <Col sm={3} xs={12}>
                    <label className="form-label label">
                    Riesgo-Causa Residual
                    </label>
                </Col>
                <Col sm={3} xs={12}>
                    <input
                        {...register("valor_riesgo_causa_residual")}
                        disabled
                        type="text"
                        className="form-control text-center texto"
                        placeholder="Riesgo-Causa Residual"
                    />
                </Col>
            </Row>
            <Row className="mb-3"><Col sm={12} xs={12}></Col></Row>
            <Row className="mb-3">
                <Col sm={3} xs={12}>
                    <label className="form-label label">
                    Nivel Riesgo-Causa Inherente
                    </label>
                </Col>

                <Col sm={3} xs={12}>
                    <input
                        {...register("nivel_riesgo_causa_ihnerente")}
                        disabled
                        type="text"
                        className="form-control text-center texto"
                        placeholder="Nivel Riesgo-Causa Inherente"
                    />
                </Col>
                <Col sm={3} xs={12}>
                    <label className="form-label label">
                    Nivel Riesgo-Causa Residual
                    </label>
                </Col>
                <Col sm={3} xs={12}>
                    <input
                        {...register("nivel_riesgo_causa_residual")}
                        disabled
                        type="text"
                        className="form-control text-center texto"
                        placeholder="Nivel Riesgo-Causa Residual"
                    />
                </Col>
            </Row>
            </Container>

    </>
    );
    }