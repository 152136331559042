import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useContext, useState } from 'react';
import { RecuperacionesContext } from '../Context/RecuperacionesContext';
import { useHandlers } from '../hooks/useHandlers';
import AADService from '../auth/authFunctions';


export const useCreateRisk = () => {
  const { setLocalRisk } = useContext(RecuperacionesContext)
  const serviceAAD = new AADService();
  const { switching, changeSwitch } = useHandlers();
  const infoUser = serviceAAD.getUser().userName;
  const [valueInputs, setValueInput] = useState({
    insumo: '',
    objetivo: '',
    correo: infoUser,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [riskinfo, setRisksinfo] = useState(null);
  const [nameElement, setNameElement] = useState('');
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedBusiness, setSelectedBusiness] = useState();
  const [initiatives, setInitiatives] = useState('');
  console.log(riskinfo)

  const router = useHistory();

  const OnCreateRisk = async () => {
    try {
      setIsLoading(true);
      setIsError(null);

      const requestBody = {
        ...valueInputs,
        nombre_elemento: nameElement.label || initiatives,
        estado:
        switching[changeSwitch] === undefined ? 0 : switching[changeSwitch],
        insumo: valueInputs.insumo,
        separador: "Word",
        objetivo: valueInputs.objetivo,
        correo: 'jonflore@bancolombia.com.co', //serviceAAD.getUser().userName
        compania: Number(selectedCompany),
        tipo_elemento:
          selectedBusiness === 'Otras iniciativas'
            ? 'Otras iniciativas'
            : selectedBusiness,
      };

      const response = await fetch(
        process.env.REACT_APP_API_URL + `/asistentero/analisando_riesgo_ro/`,
        {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: "Bearer " + serviceAAD.getToken()
          },
          /* Authorization: "Bearer " + serviceAAD.getToken()  */
        }
      );

      if (response.ok) {
        const inforisk = await response.json();
        setRisksinfo(inforisk);
        const saveRisk = inforisk?.map((risk) => ({
          idElemento: risk.id_elemento,
          idUsuario: risk.id_usuario_modificador,
        }));
        setLocalRisk(saveRisk);
      }
    } catch (error) {
      setIsError(error);
    } finally {
      setIsLoading(false);
      router.push('/EncontrarRiesgos');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValueInput({
      ...valueInputs,
      [name]: value,
    });
  };

  const handleInitiatives = (e) => {
    setInitiatives(e.target.value);
  };


  return {
    OnCreateRisk,
    handleChange,
    isLoading,
    valueInputs,
    isError,
    riskinfo,
    setNameElement,
    nameElement,
    setSelectedCompany,
    setSelectedBusiness,
    selectedCompany,
    selectedBusiness,
    handleInitiatives,
    initiatives,
  };
};
