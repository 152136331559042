import React, { useEffect, useState, useContext, useCallback } from "react";

import { Link, Routes, Route, useHistory, useLocation } from "react-router-dom";

import { Button, Row, Col, Form, Container, Alert} from "react-bootstrap";

import AADService from "../../auth/authFunctions";

import { UsuarioContext } from "../../Context/UsuarioContext";

import { withStyles, makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";

import Loader from "react-loader-spinner";
import axios from "axios";
import XLSX from "xlsx";
import { w3cwebsocket as W3CWebSocket } from "websocket";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#2c2a29",
    color: theme.palette.common.white,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#f4f4f4",
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: "60vh",
    minHeight: "60vh",
  },
});

function AlertDismissibleExample({ alerta }) {
  switch (alerta.id) {
    case 1:
      return <Alert variant="warning">Alerta</Alert>;

    case 2:
      return <Alert variant="success">{alerta.data}</Alert>;

    case 3:
      return <Alert variant="danger">{alerta.data}</Alert>;

    case 4:
      return <Alert variant="warning">Error al enviar la información</Alert>;

    case 5:
      return <Alert variant="danger">Error en el servidor</Alert>;

    case 7:
      return (
        <Alert variant="warning">
          Corrige los siguientes errores:
          <br></br>• Debe completar los campos obligatorios
        </Alert>
      );
    default:
      return <p></p>;
  }
}


export default function Ipes() {
  const serviceAAD = new AADService();

  const history = useHistory();

  const location = useLocation();

  const { dataUsuario } = useContext(UsuarioContext);
  const [estadoPost, setEstadoPost] = useState({
    alerta: { id: 0, data: null },
  });
  const classes = useStyles();

  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [archivo, setArchivo] = React.useState([]);

  const [buscando, setBuscando] = React.useState(null);

  const [ButtonEdit, SetButtonEdit] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(false);
  const [enableButton, setEnableButton] = React.useState(true);
  const [dataBusqueda, setDataBusqueda] = React.useState([]);


  const [url, setUrl] = React.useState(null);
  

  let usuario;

  usuario = serviceAAD.getUser().userName.split("@")[0]
  usuario = usuario.replace("%","")
  usuario = usuario.replace(".","")


  const client_controles = new W3CWebSocket(
    process.env.REACT_APP_WS_URL + "/ws/eros/controles_" +
    usuario
  );

  async function fileFromURL(url) {
    let urlConsulta = url;
    axios({
      url: urlConsulta,
      method: "GET",
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + serviceAAD.getToken(),
      },
    })
      .then((response) => {
        let name = urlConsulta.split("/").pop();
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        if (error.response.status == 401) {
          setEstadoPost({
            id: 3,
            data: "No estas autorizado para realizar esta accion o tu sesión esta vencida",
          });
          setTimeout(() => {
            setEstadoPost({ id: 0, data: null });
          }, 10000);
        } else if (error.response.status == 404) {
          setEstadoPost({
            id: 3,
            data: "No se encontró el archivo seleccionado",
          });
          setTimeout(() => {
            setEstadoPost({ id: 0, data: null });
          }, 10000);
        }
      });
  }

  const sendMessageSocket = (msg) => {
    if (msg) {
      if (client_controles.readyState !== 0) {
        console.log("HOLA");
        client_controles.send(JSON.stringify(msg));
      } else {
        /* console.warn("reconectando");
        const tempClient = new W3CWebSocket(
          process.env.REACT_APP_API_URL + "/ws/post_" +
            serviceAAD.getUser().userName.split("@")[0]
        );
        tempClient.onmessage = (event) =>
          recibirMensaje(JSON.parse(event.data));
        tempClient.onerror = (event) => {
          recibirError(event.data);
        };
        setClient(tempClient);
        sendMessageSocket(msg); */
      }
    }
  };

    function closeWS() {
    var mensaje = window.confirm(
      "Se ha cerrado la conexión con el servidor, es necesario recargar la página para continuar"
    );
    if (mensaje) {
      window.location.reload();
    } else {
      alert("No podrá hacer uso de los cargadores hasta recargar la página");
    }
  }

  const recibirMensaje = (data) => {
  if (data.payload.message === "Success") {
    setEstadoPost({
      id: 2,
      data: "Carga finalizada, recuerde revisar su informe de cargue",
    });
    setEnableButton(true);
    if (data.payload.URL) {
      setUrl(data.payload.URL);
    }

    setTimeout(() => {
      // if (state === 2) {
      //   history.push("/EditarAreaOrganizacional");
      // }
      setEstadoPost({ id: 0, data: null });
    }, 5000);
  }
 }

  const subirArchivo = (e) => {
    let temp = []
    let tempArchivo = []
    setArchivo([]);

    if (
      e[0].name.split(".")[1] == "xlsx" /*  ||
        e[i].name.split(".")[1] == "csv" */
    ) {
      temp.push(e[0].name);
      tempArchivo.push(e[0]);
    }

    setArchivo(tempArchivo);
  };

  const uploadArchivo = async (file, action, confirm) => {
    setEnableButton(false);
    if (file.length > 0) {
      let jsonObject = {};
      var fileReader = new FileReader();
      fileReader.onload = function (event) {
        var data = event.target.result;
        var workbook = XLSX.read(data, {
          type: "binary",
          cellDates: true,
          dateNF: "dd-mm-yyy HH:mm:ss",
        });
        workbook.SheetNames.forEach((sheet) => {
          let rowObject = XLSX.utils.sheet_to_json(workbook.Sheets[sheet], {
            raw: true,
            blankRows: true,
            defval: null,
          });
          jsonObject[sheet.trim()] = rowObject;
        });
        jsonObject["guardar"] = confirm;
        jsonObject["method"] = action;
        jsonObject["user"] = serviceAAD.getUser().userName;

        let compañia_temp = serviceAAD
          .getUser()
          .userName.split("@")[1]
          .split(".")[0]
          .toLowerCase();
        if (
          compañia_temp === "bam" ||
          compañia_temp === "banistmo" ||
          dataUsuario.idcompania === 5 ||
          dataUsuario.idcompania === 3
        ) {
          axios
            .post(process.env.REACT_APP_API_URL + "/ipesmasivo/", jsonObject, {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + serviceAAD.getToken(),
              },
            })
            .then(function (response) {
              recibirMensaje(response.data);
            })
            .catch((errors) => {
              // react on errors.
              console.error(errors);
              setTimeout(() => {
                // if (state === 2) {
                //   history.push("/EditarAreaOrganizacional");
                // }
              }, 10000);
              setEnableButton(true);
            });
        } else {
          sendMessageSocket(jsonObject);
        }
      };
      fileReader.readAsBinaryString(file[0]);
    } else {
      window.alert("No se ha seleccionado un archivo");
      setEnableButton(true);
    }
  };

  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat([], name);
      SetButtonEdit(true);
    } else {
      SetButtonEdit(false);
    }

    console.log(newSelected);
    setSelected(newSelected);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const Editar = () => {
    history.push({
      pathname: "/EditarIpe",
      state: {
        idipe: selected[0],
      },
    });
  };

  const Crear = () => {
    history.push({
      pathname: "/NuevoIpe",
    });
  };

  async function buscar(e) {
    e.persist();
    //await setBuscando(e.target.value);
    var search = data.filter((item) => {
      if (
        String(item.idipe)
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        item.nombre
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        item.responsable_ipe
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        item.compania
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        item.aristas
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        item.usuario_creador
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      ) {
        return item;
      }
    });
    //await setBuscando(e.target.value);
    //await setDataBusqueda(search);
    await setDatosBusqueda(e.target.value, search);
  }
  const setDatosBusqueda = async (buscando, busqueda) => {
    setBuscando(buscando);
    setDataBusqueda(busqueda);
  };


  useEffect(() => {
    client_controles.onopen = () => {
      console.warn("WebSocket Client Connected");
    };
    client_controles.onClose = () => {
      console.warn("WebSocket Client Disconnected");
      closeWS();
    };
    client_controles.onmessage = (message) => {
      recibirMensaje(JSON.parse(message.data));
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingData(true);

      const result = await fetch(process.env.REACT_APP_API_URL + "/ipes/", {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + serviceAAD.getToken(),
        },
      });

      let data = await result.json();

      setData(data);
      setDataBusqueda(data);
      setLoadingData(false);
    };

    fetchData();
  }, []);

  const goBack = () => {
    history.goBack();
  };

  return (
    <>
      <Container fluid>
      <AlertDismissibleExample alerta={estadoPost} />
        <Row
          style={{ marginTop: "1%", marginBottom: "0.5%" }}
          className="mb-3 mt-3"
        >
          <Col sm={4} xs={12}>
            <h1 className="titulo">Ipes</h1>
          </Col>
          <Col style={{ paddingTop: "0.3%" }} sm={4} xs={6}></Col>
        </Row>
        <Row className="mb-3">
          <Col sm={3} xs={12}>
            <label className="label forn-label">Descargar formato</label>
          </Col>
          <Col sm={3} xs={12}>
            <label className="label forn-label">Seleccione archivo</label>
          </Col>
          <Col sm={6} xs={12}>
            <input
              type="file"
              name="files"
              accept=".xlsx,.csv"
              onChange={
                (e) => {subirArchivo(e.target.files)}}
            ></input>
          </Col>
        </Row>
        <Row className="mb-3">
        <Col sm={3} xs={12}>
          <button
            type="button"
            className="btn botonPositivo"
            onClick={() => {
              fileFromURL(
              process.env.REACT_APP_API_URL + "/download/cargadores/cargador_ipes.xlsx"
            )}
            }
          >
            Descargar formato &nbsp;

          </button>
        </Col>

          {enableButton ? (
            <Col sm={3} xs={12}>
              <button
                type="button"
                className="btn botonPositivo"
                onClick={(e) => {uploadArchivo(archivo, "post", 0)}}
              >
                Cargar ipes masivamente
              </button>
            </Col>
          ) : (
            <Col className="col-auto" sm={3} xs={3}>
              <Loader
                type="Oval"
                color="#FFBF00"
                height={30}
                width={30}
                style={{
                  textAlign: "center",
                  position: "static",
                }}
              />
            </Col>
          )
              }

          <Col sm={3} xs={12}>
            {enableButton ? (
              <button
                type="button"
                className="btn botonGeneral"
                onClick={(e) => uploadArchivo(archivo, "put", 0)}
              >
                Actualizar ipes masivamente
              </button>
            ) : (
              <Col className="col-auto" sm={3} xs={3}>
                <Loader
                  type="Oval"
                  color="#FFBF00"
                  height={30}
                  width={30}
                  style={{
                    textAlign: "center",
                    position: "static",
                  }}
                />
              </Col>
            )}
          </Col>

          <Col sm={3} xs={12}>
            <Button
              id="buttonEfectos"
              type="button"
              className="btn botonPositivo"
              disabled={false}
              onClick={() => {fileFromURL(url)}}
            >
              Descargar Informe de cargue &nbsp;

            </Button>
          </Col>
        <Col sm={3} xs={0}></Col>
      </Row>
      <hr className="separador mb-3 mt-3" />
        <Row className="mb-3 mt-3">
          <Col sm={4} xs={12}>
            <Form>
              <Form.Control
                value={buscando}
                onChange={(e) => buscar(e)}
                type="text"
                placeholder="Buscar"
              />
            </Form>
          </Col>

          <Col sm={4} xs={12}></Col>

          <Col sm={2} xs={12}>
            {ButtonEdit ? (
              <Button
                className="botonNegativo"
                onClick={(event) => Editar(event)}
              >
                Editar
              </Button>
            ) : (
              <></>
            )}
          </Col>
          <Col sm={2} xs={12}>
            <Link to="NuevoIpe">
              <Button className="botonPositivo">Crear</Button>
            </Link>
          </Col>
        </Row>

        {loadingData ? (
          <Row className="mb-3 mt-5">
            <Col>
              <Loader
                type="Oval"
                color="#FFBF00"
                style={{ textAlign: "center", position: "static" }}
              />
            </Col>
          </Row>
        ) : (
          <Paper className={classes.root}>
            <TableContainer component={Paper} className={classes.container}>
              <Table className={"text"} stickyHeader aria-label="sticky table">
                {/* Inicio de encabezado */}
                <TableHead className="titulo">
                  <TableRow>
                    <StyledTableCell padding="checkbox"></StyledTableCell>
                    <StyledTableCell>ID Ipe</StyledTableCell>
                    <StyledTableCell align="left">Nombre</StyledTableCell>
                    {/* <StyledTableCell align="left">Responsable Ipe</StyledTableCell> */}
                    <StyledTableCell align="left">Compañia</StyledTableCell>
                    <StyledTableCell align="left">Aristas</StyledTableCell>
                    <StyledTableCell align="left">Usuario Creador</StyledTableCell>
                  </TableRow>
                </TableHead>
                {/* Fin de encabezado */}
                {/* Inicio de cuerpo de la tabla */}
                <TableBody>
                  {dataBusqueda
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.idipe);
                      return (
                        <StyledTableRow
                          key={row.idipe}
                          hover
                          onClick={(event) =>
                            handleClick(event, row.idipe)
                          }
                          selected={isItemSelected}
                          role="checkbox"
                          tabIndex={-1}
                        >
                          <StyledTableCell component="th" scope="row">
                            <Checkbox checked={isItemSelected} />
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row">
                            {row.idipe ? row.idipe : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.nombre ? row.nombre : null}
                          </StyledTableCell>
{/* 
                          <StyledTableCell align="left">
                            {row.responsable_ipe ? row.responsable_ipe : null}
                          </StyledTableCell> */}

                          <StyledTableCell align="left">
                            {row.responsable_ipe ? row.compania : null}
                          </StyledTableCell>
                  
                          <StyledTableCell align="left">
                            {row.responsable_ipe ? row.aristas : null}
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            {row.responsable_ipe ? row.usuario_creador : null}
                          </StyledTableCell>

                        </StyledTableRow>
                      );
                    })}
                </TableBody>
                {/* Fin de cuerpo de la tabla */}
              </Table>
            </TableContainer>
            {/* Inicio de paginación */}
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {/* Fin de paginación */}
          </Paper>
        )}
      </Container>
    </>
  );
}
